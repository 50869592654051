import * as v from 'valibot';
import type { InferOutput } from 'valibot';

export const bulkOrderFilterModel = v.object({
    marketCategory: v.nullish(v.object({
        markets: v.array(v.string()),
        categories: v.array(v.string())
    })),
    status: v.nullish(v.array(v.string())),
    showPopulatedRows: v.boolean(),
    showUpcOnly: v.nullish(v.boolean()),
    showDiscountsOnly: v.nullish(v.boolean()),
    assortmentRating: v.nullish(v.string()),
    assortmentType: v.nullish(v.string())
});

export const bulkOrderFilterDefaultValues = {
    marketCategory: {
        markets: [],
        categories: []
    },
    status: [],
    showPopulatedRows: false,
    showDiscountsOnly: true,
    showUpcOnly: true,
    assortmentRating: null,
    assortmentType: null
};

export type BulkOrderFilterModel = InferOutput<typeof bulkOrderFilterModel>;
