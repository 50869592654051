import type { Address } from '~/types';
import { BaseModel } from '~/models/base-model';

export class AddressModel extends BaseModel<Address> {
    label = null;
    firstName = '';
    lastName = '';
    company = null;
    street1 = '';
    street2 = null;
    street3 = null;
    city = '';
    state = '';
    postalCode = '';
    country = '';
    isHidden = false;
    phone = null;
    email = null;
    latitude = null;
    longitude = null;
    timezone = null;
    isShipping = true;
    isBilling = false;
    isResidential = null;
}
