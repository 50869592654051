import revive_payload_client_K5di1HRu0i from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PC7SYcJUJk from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_G6Y0FqyKW2 from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qJbcFMaufM from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.82_xzs5wcir2pi3n3of7j3s3kaspu/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_f8PtwnfPJZ from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HpyxpBHqRu from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XiZTaYPovS from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_bENr8BOCfv from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_ft3kRKcWuo from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_M2dXRUBqXN from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_yqjst6i3ieuslj2dvalna2cp2a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_K5di1HRu0i,
  unhead_PC7SYcJUJk,
  router_G6Y0FqyKW2,
  _0_siteConfig_qJbcFMaufM,
  payload_client_f8PtwnfPJZ,
  navigation_repaint_client_HpyxpBHqRu,
  check_outdated_build_client_XiZTaYPovS,
  restore_state_client_bENr8BOCfv,
  chunk_reload_client_ft3kRKcWuo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_M2dXRUBqXN,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]