import * as v from 'valibot';
import { fieldModelErrors as fErr } from '~/constants';

export const OrgTeamSignupContactModel = v.object({
    firstName: v.pipe(
        v.string(fErr.firstName),
        v.minLength(1, fErr.firstName)
    ),
    lastName: v.pipe(
        v.string(fErr.lastName),
        v.minLength(1, fErr.lastName)
    ),
    email: v.pipe(
        v.string(fErr.email),
        v.email(fErr.email)
    ),
    phone: v.pipe(
        v.string(fErr.phone),
        v.minLength(1, fErr.phone)
    ),
    gender: v.nullish(
        v.string()
    ),
    role: v.string(fErr.role)
});

export const OrgTeamSignupFormModel = v.object({
    teamName: v.pipe(
        v.string(fErr.teamName),
        v.minLength(1, fErr.teamName)
    ),
    sport: v.string(fErr.sport),
    level: v.string(fErr.level),
    teamGender: v.string(fErr.teamGender),
    city: v.pipe(
        v.string(fErr.city),
        v.minLength(1, fErr.city)
    ),
    state: v.pipe(
        v.string(fErr.state),
        v.minLength(1, fErr.state)
    ),
    country: v.string(fErr.country),
    website: v.nullish(
        v.string()
    ),
    logo: v.nullish(
        v.string()
    ),
    message: v.pipe(
        v.string(fErr.message),
        v.minLength(1, fErr.message)
    ),
    contacts: v.pipe(
        v.array(OrgTeamSignupContactModel),
        v.minLength(1, fErr.contacts)
    )
});
