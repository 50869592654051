import type { VDataTableHeaderCustom } from '~/types';

const hdr: Record<string, VDataTableHeaderCustom> = {
    uid: {
        title: 'Product Image',
        align: 'end',
        sortable: false,
        key: 'uid',
        width: 1,
        hideTitle: true
    },
    status: {
        title: 'Status',
        align: 'center',
        key: 'status',
        width: 140
    },
    assortmentType: {
        title: 'Assortment',
        align: 'center',
        key: 'assortmentType',
        width: 140
    },
    shipDate: {
        title: 'Ship Date',
        align: 'center',
        key: 'shipDate',
        width: 160
    },
    productName: {
        title: 'Product',
        align: 'start',
        key: 'productName'
    },
    detail: {
        title: 'Detail',
        align: 'start',
        key: 'detail'
    },
    colorName: {
        title: 'Color',
        align: 'start',
        key: 'colorName',
        width: 120
    },
    marketName: {
        title: 'Market',
        align: 'start',
        key: 'marketName'
    },
    categoryName: {
        title: 'Category',
        align: 'start',
        key: 'categoryName'
    },
    price: {
        title: 'Retail Price',
        align: 'center',
        key: 'price',
        width: 160
    },
    userPrice: {
        title: 'Your Price',
        align: 'center',
        key: 'userPrice',
        width: 160
    },
    userDiscountFmt: {
        title: 'Discount',
        align: 'center',
        key: 'userDiscountFmt',
        width: 160
    },
    itemNo: {
        title: 'Item No.',
        align: 'start',
        key: 'itemNo',
        width: 160
    },
    upc: {
        title: 'UPC',
        align: 'start',
        key: 'upc',
        width: 160
    }
};


// Image | Status | Ship Date | Product	Detail | Color | [Qty] | Retail Price | Your Price | Margin | Category | Market | Item No | UPC
export const baseHeadersRawFillIn: VDataTableHeaderCustom[] = [
    hdr.uid,
    hdr.status,
    hdr.shipDate,
    hdr.productName,
    hdr.detail,
    hdr.colorName,
    // QTY
    hdr.price,
    hdr.userPrice,
    hdr.userDiscountFmt,
    hdr.categoryName,
    hdr.marketName,
    hdr.itemNo,
    hdr.upc
];

export const baseSelectedHeadersFillIn = baseHeadersRawFillIn.map((h: VDataTableHeaderCustom) => h.key);
export const headersStartIdxFillIn = 6;

// Image | Assortment | Product | Detail | Color | [Qty] | Retail Price | Your Price | Discount | Category | Market | Item No | UPS
export const baseHeadersRawBooking: VDataTableHeaderCustom[] = [
    hdr.uid,
    hdr.assortmentType,
    hdr.productName,
    hdr.detail,
    hdr.colorName,
    // QTY
    hdr.price,
    hdr.userPrice,
    hdr.userDiscountFmt,
    hdr.categoryName,
    hdr.marketName,
    hdr.itemNo,
    hdr.upc
];

export const baseSelectedHeadersBooking = baseHeadersRawBooking.map((h: VDataTableHeaderCustom) => h.key);
export const headersStartIdxBooking = 5;
