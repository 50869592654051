import type {
    ComposableOptionsBase
} from '~/types';
import {
    useLogging,
    useKookies,
    useCustomAuth,
    useCmsApiUtils,
    useSessionId,
    SiteMembershipCtx
} from '~/composables';

export interface UseCustomFetchOptions extends ComposableOptionsBase {
    apiName: Nilish<string>;
}
export function useCustomFetch(options: Partial<UseCustomFetchOptions> = {}) {
    const {
        refreshTokens,
        isTokenExpired,
        isLoggedIn,
        sessionObj,
        getToken
    } = useCustomAuth();
    const {
        getKookie,
        setKookie
    } = useKookies();
    const {
        sessionIdHeader,
        sessionIdKey,
        baseURL,
        browserBaseURL,
        getCommonHeaders,
        orgIdHeader,
        siteMembershipContextHeader
    } = useCmsApiUtils({ siteContext: options.siteContext });
    const {
        logSentryError
    } = useLogging();
    const {
        getOrGenSessionId
    } = useSessionId();

    const origUa: Nilish<string> = import.meta.server ? useRequestHeader('user-agent') : null;

    return $fetch.create({
        async onRequest({ options: reqOpts }) {
            // console.time('custom-fetch');
            const siteMembershipCtx = SiteMembershipCtx();
            reqOpts.headers = new Headers(reqOpts.headers);
            let siteCurrentOrgUid = '';

            if (isLoggedIn.value) {
                if (isTokenExpired()) {
                    await refreshTokens();
                }

                const token = getToken();
                
                if (token) {
                    reqOpts.headers.set('Authorization', `Bearer ${token}`);
                }

                siteCurrentOrgUid = sessionObj.value?.currentMembership?.uid ?? '';
            }

            // Sets the X-Requested-With header
            reqOpts.headers.set('X-Requested-With', 'XMLHttpRequest');

            // Sets the baseUrl
            reqOpts.baseURL = import.meta.server ? baseURL : browserBaseURL;

            // Org ID / Org Membership Context

            // Add common headers
            for (const [key, value] of Object.entries({
                ...getCommonHeaders(),
                [siteMembershipContextHeader]: siteMembershipCtx.value,
                [orgIdHeader]: siteCurrentOrgUid
            })) {
                reqOpts.headers.set(key, value);
            }

            // Session
            const sessionIdHeaderVal = await getOrGenSessionId();
            reqOpts.headers.set(sessionIdHeader, sessionIdHeaderVal);

            if (origUa) {
                reqOpts.headers.set('user-agent', origUa);
            }

            // console.timeEnd('custom-fetch');
        },
        async onResponseError({ response }) {
            if (!import.meta.client) {
                return;
            }

            const { url, status, statusText } = response;

            logSentryError(
                new Error('custom-fetch-error'),
                {
                    tags: {
                        module: 'custom-fetch',
                        section: 'all'
                    },
                    extra: {
                        url,
                        status,
                        statusText,
                        event_id: '0'
                    },
                    level: 'error'
                }
            );
        }
    });
}
