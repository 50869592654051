

// Generic Types

export type GenericObject<T> = Record<string | number, T>;
export type GenericStringObject = GenericObject<string>;
export type GenericUidReference = { uid: string };


/**
*  @deprecated use GenericObject or GenericStringObject
*/
export interface KeyValueStringObject {
    [key: string]: string;
}






// Lists

/**
*  @deprecated Use GenericVuetifyListItem
*/
export interface GenericVSelectListItem {
    text: string;
    value: any;
}


export interface GenericVuetifyListItem<T = any> {
    title: string;
    value: T;
}


export interface SelectListItem<T = any> {
    text?: string;
    label?: string;
    value: T;
}






// API
// TODO Move me

export interface GenericApiResponse<T = string> {
    status: 'OK' | 'ERROR';
    data?: T;
}

export interface GenericApiErrorResponse {
    error?: string;
    detail?: string;
    message?: string;
}

export interface ApiGeneralException {
    detail: string;
}

export interface ApiSerializerValidationException {
    [key: string]: string;
}


export interface AwsSignedUploadRequest {
    uid: string;
    fileName: string;
    contentLength: number;
    contentType: string;
    fields?: GenericStringObject;
    conditions?: GenericStringObject;
}

export interface AwsSignedUploadResponse {
    url: string;
    fields: {
        key: string;
        AWSAccessKeyId: string;
        policy: string;
        signature: string;
    };
}



// Global Alerts
// TODO Move me
export interface StateGlobalAlertLink {
    label: string;
    to: string;
    color: string;
}

export interface StateGlobalAlert {
    text: string;
    show: boolean;
    showCloseButton: boolean;
    showReloadButton: boolean;
    type: string;
    timeout: number;
    link: Nilish<StateGlobalAlertLink>;
}

export type CreateGlobalAlert = (options?: Partial<StateGlobalAlert>) => StateGlobalAlert;


// File Upload
// TODO Move me


export type FileUploadCompleteResponse = [File, AwsSignedUploadResponse];

export type FileUploadInputValue = Record<string, AwsSignedUploadResponse>;

export interface UploadConfirmRequest {
    uid?: string;
    fileName: string;
    contentLength: number;
    fields?: GenericStringObject;
    conditions?: GenericStringObject;
}


// Router

export interface RoutableBase {
    text: string;
    disabled: boolean;
    to: string;
    nuxt: boolean;
    activeClass: string;
    append: boolean;
    exact: boolean | undefined;
    exactPath: boolean;
    exactActiveClass: string;
    link: boolean;
    href: string | object;
    replace: boolean;
    ripple: boolean | object;
    tag: string;
    target: string;
}

export type Routable = Partial<RoutableBase>;


// Errors
// TODO Move me

export class CustomNuxtError extends Error {
    statusCode: number | null = null;

    constructor(message: string) {
        super(message);
        this.name = 'CustomNuxtError';
    }
}

export interface ErrorTags {
    errors: string[];
}


// Network

export enum HttpStatusCodeType {
    UNKNOWN,
    INFORMATIONAL,
    SUCCESSFUL,
    REDIRECTION,
    CLIENT,
    SERVER
}


// Query

export type URLSearchParamsInitParams = ConstructorParameters<typeof URLSearchParams>[0];


// Sort

export interface SortableObjectWithSortOrderProp {
    sortOrder: number;
}

export interface SortableObjectWithSortProp {
    sort: number;
}
