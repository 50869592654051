import type { Stripe, StripeConstructorOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { LookupKeys } from '~/constants/lookup-keys';
import { useApiUtils } from '~/composables';


const { globalStripeObjName } = LookupKeys;
const isStripeReady = ref<boolean>(false);

export function useStripeObj() {
    const $config = useRuntimeConfig();
    const useApiUtilsObj = useApiUtils();

    function getStripeObj(): Stripe | null {
        if (import.meta.client) {
            const obj = window[globalStripeObjName as keyof Window];

            if (obj) {
                isStripeReady.value = true;
                return obj as Stripe;
            }
        }

        isStripeReady.value = false;
        return null;
    }

    function setStripeObj(stripeObj: Stripe | null): void {
        if (import.meta.client) {
            (window[globalStripeObjName as keyof Window] as Stripe | null) = stripeObj;
            isStripeReady.value = !!stripeObj;
        }
    }

    async function initStripeObj(): Promise<Stripe | Error | false | void> {
        const stripeScriptOpts: StripeConstructorOptions = $config?.public.environment?.checkout?.stripe?.scriptTagOptions ?? {} as StripeConstructorOptions;
        const stripePk: string = $config?.public.environment?.checkout?.stripe?.stripePk ?? '';
        const advancedFraudSignals: boolean = $config?.public.isProd ?? false;

        let stripeObj: Stripe | null;

        try {
            if (import.meta.client) {
                stripeObj = getStripeObj();

                if (!stripeObj) {
                    loadStripe.setLoadParameters({ advancedFraudSignals });
                    stripeObj = await loadStripe(stripePk, stripeScriptOpts);
                }

                if (!stripeObj) {
                    return new Error('Could not load Stripe');
                }

                setStripeObj(stripeObj);
                return stripeObj;
            }
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    return {
        isStripeReady,
        getStripeObj,
        setStripeObj,
        initStripeObj
    };
}
