import { useTrackingStandalone } from '~/composables';

export default defineNuxtRouteMiddleware(to => {
    if (import.meta.server) {
        return;
    }

    const { track, processTrackingParams } = useTrackingStandalone({ route: to });
    
    processTrackingParams(to.query);

    track('view_page', {
        fullPath: to.fullPath
    });
});
