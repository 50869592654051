import { isNil as lo_isNil } from 'es-toolkit';
import type { LocationQuery, LocationQueryValue, RouteParams } from 'vue-router';

export function getSingleParam(obj: LocationQuery | RouteParams, prop: string): Nullable<string> {
    const param = obj?.[prop];

    if (Array.isArray(param)) {
        return param?.[0] ?? null;
    } else if (!lo_isNil(param)) {
        return param;
    }

    return null;
}

export function getSingleParamValue(
    value: Nilish<LocationQueryValue | LocationQueryValue[]>,
    defaultValue = ''
): string {
    if (Array.isArray(value)) {
        return String(value[0]);
    } else {
        return String(value || defaultValue);
    }
}
