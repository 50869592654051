import type {
    ComposableOptionsBase,
    PaginatedPayload,
    SalesTransaction,
    OrderStatusResponse,
    OrderStatus,
    AddItemToOrderResponse,
    OrderCalculationType,
    ApiQueryParams
} from '~/types';
import {
    type BulkOrderFormModel,
} from '~/models';
import {
    SalesTransactionType
} from '~/types';
import {
    useApiUtils,
    useCustomFetch,
    useSalesTransaction
} from '~/composables';
import type { NitroFetchOptions } from 'nitropack';

export const orderExpanders = [
    'lines',
    'lines.sku',
    'details',
    'bill_to_address',
    'ship_to_address',
    'pickup_address',
    'shipping_service',
    'shipping_service.carrier',
    'can_request_return_label'
];



export interface UseOrdersOptions extends ComposableOptionsBase {}

export function useOrders(options: Partial<UseOrdersOptions> = {}) {
    const apiOrdersPath = `/v1/orders`;
    const apiOrderStatusPath = `/v1/lookup-order`;
    const useApiUtilsObj = useApiUtils();
    const $_fetch = useCustomFetch();
    const useSalesTransactionObj = useSalesTransaction(SalesTransactionType.ORDER);

    async function getCartOrders(cartUid: string): Promise<PaginatedPayload<SalesTransaction> | Error> {
        const query: NitroFetchOptions<string>['query'] = {
            cart_uid: cartUid,
            expand: orderExpanders.join(',')
        };

        try {
            return await useSalesTransactionObj.getItems<SalesTransaction>(undefined, query);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getOrderStatus(orderStatusObj: OrderStatus): Promise<OrderStatusResponse | Error> {
        const url = `${apiOrderStatusPath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: {
                email: orderStatusObj.contactEmail
            }
        };

        if ('orderNo' in orderStatusObj && orderStatusObj.orderNo) {
            config.query!.order_no = orderStatusObj.orderNo;
        }else if ('postalCode' in orderStatusObj && orderStatusObj.postalCode) {
            config.query!.postal_code = orderStatusObj.postalCode;
        }

        try {
            return await $_fetch<OrderStatusResponse>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function createOrder(orderObj: BulkOrderFormModel | Partial<SalesTransaction> | DeepPartial<SalesTransaction>, args: ApiQueryParams = {}): Promise<SalesTransaction | Error> {
        const url = `${apiOrdersPath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: orderObj,
            query: Object.assign({
                expand: [...orderExpanders].join(',')
            }, args)
        };

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateOrder(orderObj: BulkOrderFormModel | Partial<SalesTransaction>): Promise<SalesTransaction | Error> {
        const uid = orderObj.uid;
        const url = `${apiOrdersPath}/${uid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: orderObj,
            query: {
                expand: [...orderExpanders].join(',')
            }
        };

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getCalculations(
        type: OrderCalculationType,
        uid: string,
        orderObj?: BulkOrderFormModel | Partial<SalesTransaction>
    ): Promise<SalesTransaction | Error> {
        const url = `${apiOrdersPath}/${uid}/calculate-${type}/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: {
                expand: ['estimate', ...orderExpanders].join(',')
            }
        };

        if (orderObj) {
            config.body = orderObj;
        }

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setShippingRate(uid: string, rateUid: string): Promise<SalesTransaction | Error> {
        const url = `${apiOrdersPath}/${uid}/select-shipping-rate/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: { rateUid },
            query: {
                expand: ['estimate', ...orderExpanders].join(',')
            }
        };

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function deleteOrder(orderUid: string): Promise<unknown | Error> {
        const url = `${apiOrdersPath}/${orderUid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE'
        };

        try {
            const response = await $_fetch.raw<unknown>(url, config);

            if (!response || response?.status > 399) {
                throw new Error('Error deleting item.');
            }

            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function confirmOrder(orderUid: string): Promise<SalesTransaction | Error> {
        const url = `${apiOrdersPath}/${orderUid}/confirm/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: {
                expand: [...orderExpanders].join(',')
            }
        };

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function addItemToOrder(orderUid: string, skuUid: string, qty: number, inc: number): Promise<AddItemToOrderResponse | Error> {
        const url = `${apiOrdersPath}/${orderUid}/add/`;
        const config: NitroFetchOptions<string> = {
            retry: 3,
            method: 'POST',
            body: {
                sku: skuUid,
                qty,
                inc
            }
        };

        try {
            return await $_fetch<AddItemToOrderResponse>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    return {
        getCartOrders,
        getOrderStatus,
        getCalculations,
        setShippingRate,
        updateOrder,
        deleteOrder,
        confirmOrder,
        createOrder,
        addItemToOrder
    };
}
