import { noop } from '~/utils';

export function useDebug(allowOnServer = false): Console {
    const isDebug = import.meta.client ? inject<boolean>('isDebug', false) : allowOnServer;
    
    return new Proxy(console, {
        get(target: Console, prop: keyof Console) {
            if (prop in target) {
                return isDebug ? target[prop] : noop;
            }

            return noop;
        }
    });
}

export function useDebugExec(fn?: Function): void {
    const isDebug = import.meta.client && inject<boolean>('isDebug', false);

    if (isDebug) {
        fn?.();
    }
}
