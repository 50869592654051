<template>
    <!-- NOTE: BUG REQUIRES THIS PAGE w/Suspense wrapper: https://github.com/nuxt/nuxt/issues/21901 -->
    <!-- <NuxtPwaManifest /> -->
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right, rgb(var(--v-theme-primary)) 0%, transparent 100%)" />
    <NuxtLayout>
        <Suspense>
            <NuxtPage />
        </Suspense>
    </NuxtLayout>
</template>
