import 'vuetify/styles';
import { createVuetify, type VuetifyOptions } from 'vuetify';
import { md2 } from 'vuetify/blueprints';
import { useBrowserInfo } from '~/composables';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
// import DateFnsAdapter from '@date-io/date-fns';
// import enUS from 'date-fns/locale/en-US/index.js';

export default defineNuxtPlugin(async (nuxtApp) => {
    const browserInfo = useBrowserInfo();
    let isMobile: boolean = browserInfo.staticIsMobile.value;
    let ssr: VuetifyOptions['ssr'] = true;

    if (!isMobile) {
        ssr = {
            // NOTE: This should be changed to an optimal value for desktop users
            clientWidth: 1920
            // clientHeight: ?
        };
    }

    const $config = useRuntimeConfig();
    const vuetifyOpts: VuetifyOptions = {
        ssr,
        blueprint: md2,
        // TODO: Move defaults to config
        defaults: {
            global: {
                ripple: false
            },
            
            // TODO: Use defaults and remove props form components
            VTextField: {
                variant: 'outlined'
            },
            VTextarea: {
                variant: 'outlined'
            },
            VSelect: {
                variant: 'outlined'
            },
            VAvatar: {
                border: 0
            }
        },
        display: {
            mobileBreakpoint: 'md'
            // thresholds: {
            //     xs: 0,
            //     sm: 600,
            //     md: 960,
            //     lg: 1280,
            //     xl: 1920,
            //     xxl: 2560
            // }
        },
        theme: {
            ...$config.public.theme.vuetify
        },
        icons: {
            defaultSet: 'mdi',
            aliases,
            sets: {
                mdi
            }
        }
        // date: {
        //     adapter: DateFnsAdapter,
        //     locale: {
        //         en: enUS
        //     }
        // }
    };

    // NOTE: Might be faster in DEV mode to include these and disable Vuetify Loader
    // Only include in development mode
    // if ($config.public.isDev) {
    //     vuetifyOpts.components = await import('vuetify/components');
    //     vuetifyOpts.directives = await import('vuetify/directives');
    // }

    const vuetify = createVuetify(vuetifyOpts);

    nuxtApp.vueApp.use(vuetify);

    return {
        provide: {
            vuetify
        }
    };
});
