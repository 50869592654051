import { type InjectionKey } from 'vue';
import type { AccountPanel } from '~/types';

export const AccountPanelKey: InjectionKey<AccountPanel> = Symbol.for('AccountPanelKey');

export const accountPanel = reactive<AccountPanel>({
    isFullWidth: true,
    showSideNav: true
});

export function useAccountPanel(): Undefinable<AccountPanel> {
    const accountPanelInject = inject(AccountPanelKey);
    return accountPanelInject;
}
