import * as v from 'valibot';
import { fieldModelErrors as fErr, txtIsRequired } from '~/constants';

export const ProductPersonalizationRequestModel = v.object({
    senderName: v.pipe(
        v.string(fErr.senderName),
        v.minLength(1, fErr.senderName),
        v.maxLength(35, fErr.senderName)
    ),
    recipientName: v.pipe(
        v.string(fErr.recipientName),
        v.minLength(1, fErr.recipientName),
        v.maxLength(35, fErr.recipientName)
    ),
    recipientEmail: v.pipe(
        v.string(fErr.recipientEmail),
        v.email(fErr.recipientEmail)
    ),
    messageToRecipient:  v.optional(
        v.nullish(
            v.pipe(
                v.string(),
                v.maxLength(200, 'Must be 200 char or less')
            )
        )
    )
});
