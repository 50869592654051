import * as v from 'valibot';
import { fieldModelErrors as fErr, txtIsRequired } from '~/constants';

export const ProductReviewRequestModel = v.object({
    rating: v.pipe(
        v.number(fErr.rating),
        v.minValue(1, fErr.rating),
        v.maxValue(5, fErr.rating)
    ),
    title: v.string('Review title is required'),
    content: v.string('Review comments are required'),
    author: v.string('First name and last initial is required')
});
