import type { CartGuestBodyResponseData } from '~/types';
import * as v from 'valibot';
import { fieldModelErrors as fErr } from '~/constants';
import { ProductGarmentDefaultNameOnLabel } from '~/constants';

export const defaultGarmentGuestBody: NullableDeep<CartGuestBodyResponseData> = {
    name: ProductGarmentDefaultNameOnLabel,
    heightIn: null,
    weightLb: null,
    gender: 'M',
    age: 35
};

export const garmentGuestBodyModel = v.object({
    name: v.pipe(
        v.string(fErr.name),
        v.minLength(1, fErr.name)
    ),
    heightIn: v.number(fErr.height),
    weightLb: v.pipe(
        v.number(fErr.weight),
        v.minValue(35, 'Must be greater than 35'),
        v.maxValue(500, 'Must be less than 500')
    ),
    gender: v.union(
        [
            v.literal('M'),
            v.literal('F')
        ],
        fErr.gender
    ),
    age: v.number(fErr.age)
});
