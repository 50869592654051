import type {
    PaginatedPayload,
    CustomPagination,
    PaginatedRequest,
    SalesTransactionTypeObj,
    SalesTransaction,
    SalesTransactionLine
} from '~/types';
import { SalesTransactionType, SalesTransactionUidPrefix } from '~/types';
import {
    useApiUtils,
    useCustomFetch,
    orderExpanders
} from '~/composables';
import { omit as lo_omit } from 'es-toolkit';
import { merge as lo_merge } from 'lodash-es';
import { pathJoin } from '~/utils';
import type { NitroFetchOptions } from 'nitropack';


export function useSalesTransaction(type: Nullable<SalesTransactionType>) {
    const tnxType = type ? getEndpointFromType(type) : null;

    if (!tnxType) {
        console.warn('useSalesTransaction: Invalid type provided.');
    }

    const apiBasePath = `${tnxType ?? ''}`;
    const useApiUtilsObj = useApiUtils();
    const $_fetch = useCustomFetch();

    async function getItems<T = SalesTransaction>(
        args: PaginatedRequest = {},
        query: NitroFetchOptions<string>['query'] = {}
    ): Promise<PaginatedPayload<T> | Error> {
        const url = args?.url ?? `${apiBasePath}/`;

        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: {
                expand: [...orderExpanders].join(',')
            }
        };

        lo_merge(config.query, args?.params, query, {
            // Base params here
        });

        let paginatedPayload: PaginatedPayload<T>;

        try {
            const response = await $_fetch<CustomPagination<T>>(url, config);

            paginatedPayload = {
                pagination: lo_omit(response, ['results']),
                data: response.results
            };

            return paginatedPayload;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getItem(uid: string): Promise<SalesTransaction | Error> {
        const url = `${apiBasePath}/${uid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: {
                expand: [...orderExpanders].join(',')
            }
        };

        try {
            return await $_fetch<SalesTransaction>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    function getOrderReturnLabel(uid: string): string {
        const baseUrl = useApiUtilsObj.getApiBaseUrl('cms') || '';
        return pathJoin(baseUrl, `/orders/return-label/${uid}/`);
    }

    // Internal

    function getEndpointFromType(type: SalesTransactionType): string {
        switch (type) {
            case SalesTransactionType.ORDER:
                return `/v1/orders`;
            case SalesTransactionType.INVOICE:
                return `/v1/invoices`;
            case SalesTransactionType.CREDIT_MEMO:
                return `/v1/credit-memos`;
            default:
                return '';
        }
    }

    return {
        getItems,
        getItem,
        getOrderReturnLabel
    };
}
