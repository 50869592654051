import type { NuxtError } from '#app';
import type { MaybeRef } from '@vueuse/core';
import { unref } from 'vue';

export function useCustomError() {
    function getNuxtErrorObj(statusCode: number): NuxtError {
        const message = getErrorMsgFromCode(statusCode);
        return createError({ statusCode, message });
    }

    function getErrorMsgFromCode(statusCode: MaybeRef<number>): string {
        statusCode = unref(statusCode);

        switch (statusCode) {
            case 401:
                return 'Unauthorized';
            case 403:
                return 'Forbidden';
            case 404:
                return 'Page Not Found';
            case 503:
                return 'Site Offline';
            default:
                return 'An error occurred';
        }
    }

    return {
        getNuxtErrorObj,
        getErrorMsgFromCode
    };
}
