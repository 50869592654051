import type { LocationQuery } from '#vue-router';
import type { SignUpQueryParams, UserSignUpRequest } from '~/types';
import { getSingleParam, objFilterNulls } from '~/utils';

const signupStateParams = ref<Partial<UserSignUpRequest>>({});

export function useCreateAccount() {
    const route = useRoute();

    function setQueryParams<T = Partial<UserSignUpRequest>>(): T {
        const q = route.query;
        const token = getSingleParam(q, 'token');
        const firstName = getSingleParam(q, 'first_name') || '';
        const lastName = getSingleParam(q, 'last_name') || '';
        const gender = getSingleParam(q, 'gender');
        const email = getSingleParam(q, 'email') || '';

        Object.assign(signupStateParams.value, {
            firstName,
            lastName,
            gender,
            email,
            token
        });

        return objFilterNulls<T>(signupStateParams.value);
    }

    function getQueryParams(): LocationQuery {
        return Object.assign({}, signupStateParams.value) as LocationQuery;
    }
    
    return {
        signupStateParams,
        setQueryParams,
        getQueryParams
    };
}
