import { joinURL } from 'ufo';
import { isUrl } from './';

export function pathJoin(basePath: string, ...paths: string[]): string {
    let path = '';

    // Sometimes first item in paths is a url, this should override basePath
    if (Array.isArray(paths) && paths.length && isUrl(paths[0])) {
        basePath = paths.shift() ?? '';
    }

    path = joinURL('', ...paths);

    if (isUrl(basePath)) {
        const url = new URL(basePath);
        url.pathname = joinURL(url.pathname, path);
        path = url.toString();
    } else {
        path = joinURL(basePath, path);
    }

    return path;
}
