import type { CartSecretCodes } from '~/types';
import { type InjectionKey } from 'vue';
import { CouponCodeSecrets } from '~/constants';

export const CartSecretCodesKey: InjectionKey<CartSecretCodes> = Symbol.for('CartSecretCodes');

const populatedSecretCodes: CartSecretCodes = new Map();
CouponCodeSecrets.map((code) => populatedSecretCodes.set(code, null));

export const cartSecretCodes = reactive<CartSecretCodes>(populatedSecretCodes);

export function useCartSecretCodes(): CartSecretCodes {
    const createCartSecretCodes = inject(CartSecretCodesKey);

    if (!createCartSecretCodes) {
        throw new Error('Could not resolve Cart Secret Code provider');
    }

    return createCartSecretCodes;
}
