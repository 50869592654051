import * as v from 'valibot';
import { toTypedSchema } from '@vee-validate/valibot';
import {
    useUserLite,
    useSkusTable,
    useOrders,
    useBulkOrderFilter,
    useRouteQueryState
} from '~/composables';
import {
    OrderTotalsModel,
    bulkOrderFilterDefaultValues,
    bulkOrderFilterModel,
    type BulkOrderFormModel,
    type BulkOrderFilterModel
} from '~/models';
import type {
    SkuFilters,
    BulkOrderItem,
    BulkOrderInputModel,
    SalesTransaction,
    SalesTransactionLine,
    PaginatedRequest,
    AddItemToOrderResponse,
    BulkOrderGlobalState,
    OrderCalculationType,
    ApiQueryParams
} from '~/types';
import {
    SalesTransactionType,
    SalesTransactionOrderStatus,
    SalesTransactionOrderInternalStatus
} from '~/types';
import {
    createSharedComposable,
    useEventBus,
    type EventBusKey
} from '@vueuse/core';
import { bulkOrderDateModel, bulkOrderFormModel } from '~/models';
import { customOrderColumnPfx } from '~/constants';
import type { NitroFetchOptions } from 'nitropack';
import { isNil as lo_isNil } from 'es-toolkit';
import { isError as lo_isError } from 'es-toolkit';
import { castArray as lo_castArray } from 'es-toolkit/compat';
import { pick as lo_pick } from 'es-toolkit';
import { isEmpty as lo_isEmpty } from 'lodash-es';
import { merge as lo_merge } from 'lodash-es';


export const bulkOrderItemsSortFn = (a: BulkOrderItem, b: BulkOrderItem) => Date.parse(a.requestedShipDate ?? '0') - Date.parse(b.requestedShipDate ?? '0');
export const bulkOrderItems = reactive<BulkOrderInputModel>(new Map());
export const bulkOrderGlobalState = reactive<BulkOrderGlobalState>({
    hasAsap: false,
    hasValidQtyOrders: false,
    orderTotals: Object.assign({}, { ...OrderTotalsModel })
});
export const bulkOrderSettings = reactive({
    fontSizeStep: 2
});
export const filtersPending = ref<Nullable<BulkOrderFilterModel>>(null);

const tableSearchText = ref<string>('');
const keyUseBulkOrderFilterEvent: EventBusKey<SkuFilters> = Symbol.for('key-use-bulk-order-filter-event');
const ordersList = reactive<Map<string, SalesTransaction>>(new Map());
const isFilterApplied = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const getOrdersCount = ref<number>(0);
const isOrderTypeBooking = ref<boolean>(false);

export function useBulkOrder() {
    const $config = useRuntimeConfig();
    const { isPreProd } = $config.public;

    const { on: onFilterChange, emit: emitFilterChange } = useEventBus(keyUseBulkOrderFilterEvent);
    const { isOrgMemberAllowed } = useUserLite();
    const useOrdersObj = useOrders();
    const canUseBookingFeature = readonly(
        ref<boolean>(isOrgMemberAllowed('flag', 'booking_orders_enabled'))
    );
    
    const {
        addCustomHeader,
        deleteCustomHeader,
        toggleCustomHeaderVisibility,
        getColumnKey,
        toggleBookingColumns,
        destroy: destroyUseSkusTable
    } = useSkusTable();
    const useSalesTxnObj = useSalesTransaction(SalesTransactionType.ORDER);
    const {
        queryParamsRequest,
        getApiQueryParams,
        updatePagination,
        reset: resetApiQueryParams
    } = useApiQueryParams(`useBulkOrderParams`, false);

    const {
        setState: setFilterState,
        getState: getFilterState,
        clearState: clearFilterState
    } = useRouteQueryState('F');

    const {
        setState: setOptionsState,
        getState: getOptionsState,
        currentState: currentOptionsState,
        clearState: clearOptionsState
    } = useRouteQueryState('O');

    // Get params
    const orderBaseQueryParams: ApiQueryParams<typeof queryParamsRequest> = {
        // TODO: Is this still needed?
        status: 'pending',
        
        source: 'bulk',
        expand: 'lines,lines.sku,details,shipping_service',
        get_or_create: 'true',
        is_booking: isOrderTypeBooking
    };

    if (isPreProd) {
        orderBaseQueryParams.is_test = 'true';
    }

    Object.assign(queryParamsRequest.value, orderBaseQueryParams);

    const { createFilters, filtersObj } = useBulkOrderFilter({
        bulkOrderItems
    });
    const filterStateObj = getFilterState();
    const optionsStateObj = getOptionsState();

    // Set any options vars here
    if (optionsStateObj.isBooking) {
        isOrderTypeBooking.value = true;
        toggleBookingColumns(true);
    }

    const {
        handleSubmit: handleFilterFormSubmit,
        defineField: filterFormDefineField,
        values: filterFormValues,
        meta: filterFormMeta,
        resetForm: filterFormReset,
        setValues: filterFormSetValues
    } = useForm({
        validationSchema: toTypedSchema(
            // @ts-ignore
            bulkOrderFilterModel
        ),

        initialValues: lo_merge({}, bulkOrderFilterDefaultValues, filterStateObj)
    });

    // Filter

    function onResetFilter(): void {
        isFilterApplied.value = false;
        filtersObj.clear();
        clearFilterState();
        filterFormReset({ values: bulkOrderFilterDefaultValues });
        onApplyFilter(filterFormValues);
    }

    function onApplyFilter(values: BulkOrderFilterModel): void {
        filtersPending.value = values;

        // Deferring filters if needed
        if (values.showPopulatedRows && !bulkOrderItems.size) {
            console.info('FILTERS DEFERRED...');
            return;
        }

        applyFilters();
    }


    function applyDefaultFilters(): boolean {
        onApplyFilter(filterFormValues);
        return true;
    }

    function applyFilters(): boolean {
        if (!filtersPending.value) {
            console.info('No pending filters to apply...');
            return false;
        }

        // Apply
        const values = filtersPending.value;
        const filtersObj = createFilters(values, currentOptionsState.value);
        isFilterApplied.value = filtersObj.size > 0;
        setFilterState(values);
        emitFilterChange(filtersObj);
        
        // Reset
        filtersPending.value = null;
        return true;
    }

    function applyAnyFilters(): boolean {
        return applyFilters() || applyDefaultFilters();
    }

    // Bulk Order Items

    async function qtyChange(uid: string, key: string, qty: number): Promise<void> {
        const bulkOrderObj = bulkOrderItems.get(key);
        const orderObj = getOrderByColKey(key);

        if (bulkOrderObj && orderObj) {
            bulkOrderObj.progress = true;
            const { order } = bulkOrderObj;

            if (qty === 0) {
                delete order[uid];
            } else {
                order[uid] = qty;
            }

            const response = await useOrdersObj.addItemToOrder(orderObj.uid, uid, qty, ++bulkOrderObj.totalsIncCount);
            const updateStatus = await updateItemTotals(bulkOrderObj, response);

            // updateStatus is a fail-safe to prevent the progress indicator from being stuck
            if (updateStatus < 0 || (updateStatus && 'lines__qty' in response && response.lines__qty === bulkOrderObj.totals?.lines__qty)) {
                bulkOrderObj.progress = false;
            }

            return await updateState();
        }
    }

    async function getOrder(uid: string): Promise<SalesTransaction | void | Error> {
        isLoading.value = true;

        const response = await useSalesTxnObj.getItem(uid);

        isLoading.value = false;

        if (lo_isError(response)) {
            // TODO: Handle error
            return response;
        }

        return response;
    }

    async function getOrders(): Promise<SalesTransaction[] | void | Error> {
        isLoading.value = true;

        // Reloading the orders
        if (getOrdersCount.value > 0) {
            clearOrderItems();
        }

        const paginatedRequest: PaginatedRequest = {
            params: getApiQueryParams()
        };

        const query: NitroFetchOptions<string>['query'] = {};
        const response = await useSalesTxnObj.getItems(paginatedRequest, query);

        isLoading.value = false;

        if (lo_isError(response)) {
            return response;
        } else if (response && Array.isArray(response.data)) {
            getOrdersCount.value++;
            initOrderItems(response.data);
            updatePagination(response.pagination);
            return response.data;
        }
    }

    async function createOrder(orderObj?: BulkOrderFormModel | Partial<SalesTransaction>): Promise<SalesTransaction | Error> {
        // Make request
        if (orderObj && 'source' in orderObj) {
            orderObj.source = 'bulk';
        }

        // TODO: Fix type
        // @ts-ignore
        const queryParams: ApiQueryParams = lo_pick(orderBaseQueryParams, ['status', 'is_test', 'source']);

        const response  = await useOrdersObj.createOrder(orderObj || {}, queryParams);

        // Error occurred
        if (lo_isError(response)) {
            return response;
        }

        registerBulkOrderItem(response);
        updateState();
        return response;
    }

    async function updateOrder(orderObj: BulkOrderFormModel | Partial<SalesTransaction>): Promise<SalesTransaction | Error> {
        if (!orderObj.uid) {
            console.info('onCreateOrder: dateObj is null');
            return new Error('Order date must be valid');
        }

        const key = getColumnKey(orderObj.uid);

        if (key && !bulkOrderItems.has(key)) {
            console.info('Order for this date already exists...');
            return new Error('Order for this date already exists');
        }

        // Make request
        const response  = await useOrdersObj.updateOrder(orderObj);

        // Error occurred
        if (lo_isError(response)) {
            return response;
        }

        registerBulkOrderItem(response);
        updateState();
        validateBulkOrderItem(key, orderObj);
        return response;
    }

    async function deleteOrder(uid: string): Promise<boolean | Error> {
        if (!uid) {
            return false;
        }

        const response  = await useOrdersObj.deleteOrder(uid);

        if (lo_isError(response)) {
            return new Error('Error deleting order');
        }

        const orderObj = ordersList.get(uid);

        if (!orderObj) {
            return new Error('Cannot find order to delete');
        }

        unregisterBulkOrderItem(orderObj);
        updateState();
        return true;
    }

    function clearOrderItems(): void {
        for (const [key, value] of ordersList) {
            unregisterBulkOrderItem(value);
        }

        ordersList.clear();
        bulkOrderItems.clear();

        updateState();
    }

    function initOrderItems(items: SalesTransaction[]): void {
        const { order } = optionsStateObj;
        const shownOrders = !order ? null : order.split(',');

        ordersList.clear();
        bulkOrderItems.clear();

        items.forEach(item => {
            const showOrder = !shownOrders || shownOrders.includes(item.orderNo);
            registerBulkOrderItem(item, showOrder);
        });

        updateState();
        onOrdersLoaded();
    }

    function registerBulkOrderItem(orderObj: SalesTransaction, showOrder: boolean = true): boolean {
        if (!orderObj.uid) {
            return false;
        }

        const key = getColumnKey(orderObj.uid);
        const { uid, requestedShipDate } = orderObj;

        if (!key || !uid) {
            return false;
        }

        ordersList.set(uid, orderObj);

        const isBooking = orderObj.isBooking;
        const isValid = (isBooking || !requestedShipDate) ? true : v.safeParse(bulkOrderDateModel, requestedShipDate).success;
        const title = isBooking ? orderObj.requestedDeliveryDate ?? '' : requestedShipDate ?? 'Ship ASAP';
        const isAsapShipDate = !requestedShipDate;
        const isHidden = !showOrder;
        const isReadOnly = orderObj.status !== SalesTransactionOrderStatus.NOT_SUBMITTED;
        
        const updated = orderObj.updated;

        const order: Record<string, number> = {};
        let lines__qty = 0;
        orderObj.lines?.forEach((line: SalesTransactionLine) => {
            if (line.sku?.uid) {
                order[line.sku.uid] = line.qty;
            }

            lines__qty += line.qty;
        });

        // Add top-level key if it doesn't exist
        bulkOrderItems.set(key, {
            title,
            requestedShipDate: orderObj.requestedShipDate,
            uid,
            colKey: key,
            isValid,
            isAsapShipDate,
            isHidden,
            isReadOnly,
            isHighlighted: false,
            isFocused: false,
            isBooking,
            updated,
            order,
            orderNo: orderObj.orderNo,
            shipDate: orderObj.shipDate,
            purchaseOrderNo: orderObj.purchaseOrderNo,
            totals: {
                success: true,
                sku: '',
                inc: -1,
                lines__qty,
                shipping_total: orderObj.shippingTotal,
                order_total: orderObj.total,
                tax_total: orderObj.taxTotal
            },
            totalsIncCount: 0,
            progress: false
        });


        // Add Column
        addCustomHeader(key, {
            title,
            width: 40,
            value: requestedShipDate ?? '0',
            align: 'center',
            isCustom: true,
            isHidden,
            cellProps: {
                class: 'custom-cell-qty-input'
            }
        });

        return true;
    }

    function toggleOrdersVisibility(colKeys: string | string[]): void {
        const total = bulkOrderItems.size;
        colKeys = lo_castArray(colKeys);
        const enabled: string[] = [];

        bulkOrderItems.forEach((obj, key) => {
            const show = colKeys.includes(key);
            obj.isHidden = !show;
            toggleCustomHeaderVisibility(key, show);

            if (show) {
                enabled.push(obj.orderNo);
            }
        });

        if (total > enabled.length) {
            setOptionsState({ order: enabled.join(',') });
        }
    }

    function unregisterBulkOrderItem(orderObj: BulkOrderFormModel | Partial<SalesTransaction>): boolean {
        if (!orderObj.uid) {
            return false;
        }

        const key = getColumnKey(orderObj.uid);
        
        if (!key) {
            return false;
        }

        // Remove Header
        deleteCustomHeader(key);

        // Remove Key
        if (bulkOrderItems.has(key)) {
            bulkOrderItems.delete(key);
        }

        return true;
    }

    async function updateItemTotals(bulkOrderObj: BulkOrderItem, totalsObj: AddItemToOrderResponse | Error): Promise<number> {
        if (lo_isError(totalsObj) || !bulkOrderObj) {
            return -1;
        }

        if (!bulkOrderObj || (bulkOrderObj && bulkOrderObj.totalsIncCount > totalsObj.inc)) {
            return 0;
        }

        bulkOrderObj.totals = totalsObj;
        return 1;
    }

    async function calculateTotals(
        type: OrderCalculationType,
        orderUid: string,
        orderObj?: BulkOrderFormModel | Partial<SalesTransaction>
    ): Promise<SalesTransaction | Error> {
        return await useOrdersObj.getCalculations(type, orderUid, orderObj);
    }

    async function setShippingRate(orderUid: string, rateUid: string): Promise<SalesTransaction | Error> {
        return await useOrdersObj.setShippingRate(orderUid, rateUid);
    }

    async function confirmOrder(orderUid: string, keepOrder = false): Promise<SalesTransaction | Error> {
        const response = await useOrdersObj.confirmOrder(orderUid);

        if (response && !lo_isError(response)) {
            unregisterBulkOrderItem(response as any);

            if (keepOrder) {
                registerBulkOrderItem(response as any);
            }
        }

        return response;
    }

    async function updateState(): Promise<void> {
        let hasAsap = false;
        let hasValidQtyOrders = false;
        let orderTotals = Object.assign({}, { ...OrderTotalsModel });

        for (const [_, value] of bulkOrderItems) {
            if (value) {
                if (lo_isNil(value.requestedShipDate)) {
                    hasAsap = true;
                }

                if (!lo_isEmpty(value.order)) {
                    hasValidQtyOrders = true;
                }

                // Update Order Totals
                orderTotals.qty += value.totals?.lines__qty ?? 0;
                orderTotals.total += value.totals?.order_total ?? 0;
            }
        }

        Object.assign(bulkOrderGlobalState, { hasAsap, hasValidQtyOrders, orderTotals });
    }

    async function onOrdersLoaded(): Promise<void> {
        applyAnyFilters();
        return;
    }

    function validateBulkOrderItem(key: string, orderObj: BulkOrderFormModel | Partial<SalesTransaction>): void {
        const bulkOrderObj = bulkOrderItems.get(key);

        if (bulkOrderObj) {
            const isValid = v.safeParse(bulkOrderFormModel, orderObj).success;
            bulkOrderObj.isValid = isValid;
        }
    }

    function destroy(): void {
        tableSearchText.value = '';
        isLoading.value = false;
        isFilterApplied.value = false;
        getOrdersCount.value = 0;

        Object.assign(bulkOrderGlobalState, {
            hasAsap: false,
            hasValidQtyOrders: false,
            orderTotals: Object.assign({}, { ...OrderTotalsModel })
        });

        ordersList.clear();
        filtersObj.clear();
        bulkOrderItems.clear();
        resetApiQueryParams();
        destroyUseSkusTable();
    }

    // Helpers

    function getOrderByColKey(colKey: string): Nullable<SalesTransaction> {
        const key = colKey.replace(customOrderColumnPfx, '');
        return ordersList.get(key) ?? null;
    }

    return {
        isLoading,
        setOptionsState,
        isFilterApplied,
        isOrderTypeBooking,
        canUseBookingFeature,
        bulkOrderGlobalState,
        bulkOrderItems,
        bulkOrderSettings,
        tableSearchText,
        qtyChange,
        onApplyFilter,
        onResetFilter,
        applyDefaultFilters,
        filtersObj,
        filterFormMeta,
        filterFormValues,
        filterFormSetValues,
        filterFormDefineField,
        filtersPending: readonly(filtersPending),
        handleFilterFormSubmit,
        onFilterChange,
        getOrder,
        getOrders,
        getOrderByColKey,
        createOrder,
        updateOrder,
        calculateTotals,
        setShippingRate,
        confirmOrder,
        deleteOrder,
        toggleOrdersVisibility,
        destroy
    };
}

export const useBulkOrderShared = createSharedComposable(useBulkOrder);
