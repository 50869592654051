import * as v from 'valibot';
import { fieldModelErrors as fErr, txtIsRequired } from '~/constants';

export const GiftCardRequestModel = v.object({
    secretCode: v.pipe(
        v.string(fErr.code),
        v.minLength(10, fErr.code),
        v.maxLength(10, fErr.code)
    )
});
