import { UserBodyMale } from '~/types';
import type { BodyTypeListItem } from '~/types';

export const BodyTypeList: BodyTypeListItem[] = [
    {
        text: 'Slim',
        bodyType: UserBodyMale.SLIM,
        imageUrl: '/static/common/images/bodies/male/Body-Type-Male-Slim-1.jpg'
    },
    {
        text: 'Average',
        bodyType: UserBodyMale.AVERAGE,
        imageUrl: '/static/common/images/bodies/male/Body-Type-Male-Average-1.jpg'
    },
    {
        text: 'Athletic',
        bodyType: UserBodyMale.ATHLETIC,
        imageUrl: '/static/common/images/bodies/male/Body-Type-Male-Athletic-1.jpg'
    },
    {
        text: 'Husky',
        bodyType: UserBodyMale.HUSKY,
        imageUrl: '/static/common/images/bodies/male/Body-Type-Male-Husky-1.jpg'
    }
];
