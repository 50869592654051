import type { TokenCookieNames } from '~/types';
import type { RuntimeConfig } from 'nuxt/schema';
import { useRuntimeConfig } from '#imports';
import { pathJoin } from '~/utils';

type AuthCookie = RuntimeConfig['public']['auth']['cookie'];
type AuthEndpoints = RuntimeConfig['public']['auth']['endpoints'];
type AuthEndpointsKeys = keyof AuthEndpoints;


const localAuthBasePath = '/api-local/auth';

export function useAuthUtils() {
    const $config = useRuntimeConfig();
    const { storagePrefix } = $config.public;
    const { apiBaseURL } = $config.public;

    const cookieNames: TokenCookieNames = {
        access: `${storagePrefix}_access`,
        accessExp: `${storagePrefix}_access_exp`,
        refresh: `${storagePrefix}_refresh`,
        refreshExp: `${storagePrefix}_refresh_exp`
    };

    const cookieConfig: Nullable<AuthCookie> = $config.public?.auth?.cookie ?? null;
    const authEndpoints: Nullable<AuthEndpoints> = $config.public?.auth?.endpoints ?? null;
    
    function getServerAuthEndpoint(endpointName: AuthEndpointsKeys): Undefinable<string> {
        const endpoint = authEndpoints?.[endpointName]
        
        if (!endpoint) {
            return;
        }

        return pathJoin(apiBaseURL, endpoint);
    }

    function getLocalAuthEndpoint(path: string) {
        return pathJoin(localAuthBasePath, path);
    }

    return {
        cookieNames,
        cookieConfig,
        authEndpoints,
        getServerAuthEndpoint,
        getLocalAuthEndpoint
    };
}
