import type { ThemeType } from '~/types';

export function useCurrentTheme() {
    // NOTE: colorMode Disabled
    // const colorMode = useColorMode();
    const colorMode = ref<string>('light');

    const themeName = computed<ThemeType>(() => colorMode.value === 'dark' ? 'dark' : 'light');
    const themeClass = computed<string>(() => `v-theme--${themeName.value}`);
    const themeIsDark = computed<boolean>(() => themeName.value === 'dark');

    return {
        themeName,
        themeClass,
        themeIsDark
    };
}
