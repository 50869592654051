// import type { GenericVuetifyListItem } from '~/types';


export const AssortmentRatingsList: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E'
];
