import { customOrderColumnPfx } from '~/constants';
import type { VDataTableHeaderCustom } from '~/types';
import {
    baseHeadersRawFillIn,
    headersStartIdxFillIn,
    baseHeadersRawBooking,
    headersStartIdxBooking,
    baseSelectedHeadersFillIn,
    baseSelectedHeadersBooking
} from '~/models';


const customDateHeaders = reactive<VDataTableHeaderCustom[]>([]);
const baseHeaders = ref<VDataTableHeaderCustom[]>(baseHeadersRawFillIn);
const customHeadersStartIndex = ref(headersStartIdxFillIn);
const selectedBaseHeaderNames = ref<string[]>(baseSelectedHeadersFillIn);

export function useSkusTable() {
    const columnsOrderDateKeys = computed<string[]>(() => customDateHeaders.map((h: VDataTableHeaderCustom) => h.key));
    const sortedCustomDateHeaders = computed(() => customDateHeaders.sort((a: VDataTableHeaderCustom, b: VDataTableHeaderCustom) => Date.parse(a.value) - Date.parse(b.value)));

    const headers = computed(() => {
        const h = baseHeaders.value.slice(0);
        h.splice(customHeadersStartIndex.value, 0, ...sortedCustomDateHeaders.value);
        return h.filter((h: VDataTableHeaderCustom) => selectedBaseHeaderNames.value.includes(h.key) || (h.isCustom && !h.isHidden));
    });

    function addCustomHeader(key: string, props: object): void {
        if (isColumnExists(key)) {
            console.info('Column already exists: ', key);
            return;
        }

        customDateHeaders.push({
            key,
            sortable: false,
            ...props
        });
    }

    function updateCustomHeader(key: string, props: object): void {
        const header = customDateHeaders.find((h: VDataTableHeaderCustom) => {
            return h.key === getColumnKey(key);
        });

        if (!header) {
            console.info('Cannot update: Column not found: ', key);
            return;
        }
        
        Object.assign(header, props);
    }

    function toggleCustomHeaderVisibility(key: string, show: boolean): void {
        updateCustomHeader(key, { isHidden: !show });
    }

    function deleteCustomHeader(colKey: string): void {
        const headerIdx = customDateHeaders.findIndex((h: VDataTableHeaderCustom) => h.key === colKey);

        if (headerIdx === -1) {
            console.info('Cannot delete: column not found: ', colKey);
            return;
        }
        
        customDateHeaders.splice(headerIdx, 1);
    }

    function toggleBookingColumns(isBooking?: boolean): void {
        baseHeaders.value = isBooking ? baseHeadersRawBooking : baseHeadersRawFillIn;
        selectedBaseHeaderNames.value = isBooking ? baseSelectedHeadersBooking : baseSelectedHeadersFillIn;

        nextTick(() => {
            customHeadersStartIndex.value = isBooking ? headersStartIdxBooking : headersStartIdxFillIn;
        });
    }

    function destroy(): void {
        customDateHeaders.splice(0);
    }

    // Helpers

    function getColumnKey(id: string): string {
        if (id.startsWith(customOrderColumnPfx)) {
            return id;
        }

        return `${customOrderColumnPfx}${id}`;
    }

    function isColumnExists(id: string): boolean {
        return !!customDateHeaders.find((h: VDataTableHeaderCustom) => h.key === getColumnKey(id));
    }

    return {
        customOrderColumnPfx,
        baseHeaders,
        customDateHeaders,
        customHeadersStartIndex,
        selectedBaseHeaderNames,
        columnsOrderDateKeys,
        headers,
        sortedCustomDateHeaders,
        addCustomHeader,
        updateCustomHeader,
        toggleCustomHeaderVisibility,
        toggleBookingColumns,
        deleteCustomHeader,
        getColumnKey,
        destroy
    };
}
