import type { IResult } from 'ua-parser-js';

export const IsMobileFromHeaders = () => useState<Nullable<boolean>>('keyIsMobileFromHeaders', () => null);
export const BrowserInfo = () => useState<Nullable<IResult>>('keyBrowserInfo', () => null);
export const UserAgentCssClass = () => useState<Nullable<string>>('keyUserAgentCssClass', () => null);

export function useBrowserInfo() {

    const browserInfo = BrowserInfo();
    const isMobileFromHeaders = IsMobileFromHeaders();
    const staticDeviceTypeSpecific = browserInfo.value?.device.type;

    // NOTE: Override for testing
    const staticIsMobile = computed<boolean>(() => isMobileFromHeaders.value ?? staticDeviceTypeSpecific === 'mobile');
    // const staticIsMobile = computed<boolean>(() => staticDeviceTypeSpecific === 'mobile');


    const staticDeviceType = staticIsMobile.value ? 'mobile' : 'desktop';


    function setStaticIsMobileByWidth(width?: Nilish<number | string | string[]>): void {
        if (width) {
            width = Array.isArray(width) ? width[0] : width;
            const w = parseInt(`${width}`, 10);

            if (!isNaN(w)) {
                isMobileFromHeaders.value = w < 960;
            }
        }
    }

    return {
        browserInfo,
        isMobileFromHeaders,
        staticDeviceType,
        staticIsMobile,
        staticDeviceTypeSpecific,
        setStaticIsMobileByWidth
    };
}
