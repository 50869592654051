import {
    parseDt
} from './';
import {
    isBefore
} from '@formkit/tempo';

export function dtIsPast(dt: string | Date): boolean {
    return isBefore(parseDt(dt), new Date());
}

export function isDateValid(dt: Date | unknown): boolean {
    return dt instanceof Date && isFinite(+dt);
}
