<template>
    <div
        class="logo-component d-flex fill-height align-center flex-grow-1 ma-0 position-relative"
        :class="`justify-${justify}`"
    >
        <span class="d-none" role="banner">{{ logoTitle }}</span>
        <img
            v-if="logoUrl"
            :src="logoUrl"
            :alt="logoTitle"
            :aria-label="logoTitle"
            :style="imgStyle"
            loading="lazy"
        />
    </div>
</template>

<script lang="ts" setup>
    import {
        useCdnResource,
        useCurrentTheme
    } from '~/composables';
    import {
        useDisplay
    } from 'vuetify';
    import type {
        LogoThemeSize,
        LogoThemeImage
    } from '~/types';
    import type {
        CSSProperties,
        WatchStopHandle
    } from 'vue';

    const props = withDefaults(
        defineProps<{
            width?: number | string;
            height?: number | string
            staticSize?: Nilish<keyof LogoThemeSize>;
            justify?: 'start' | 'end' | 'center';
            logoTypeOverride?: Nilish<keyof LogoThemeSize>;
        }>(),
        {
            width: 'auto',
            height: 'auto',
            justify: 'center',
            logoTypeOverride: null
        }
    );

    const $config = useRuntimeConfig();
    const logoTitle = $config.public.brand.title as string;
    const logoConfig = $config.public.logo;

    const useCdnResourceObj = useCdnResource();
    const useDisplayObj = useDisplay();
    const logoUrl = ref<string>('');
    const { themeName } = useCurrentTheme();
    const imgStyle: CSSProperties = reactive({
        aspectRatio: 'unset',
        width: 'auto',
        height: 'auto'
    });
    let unWatch: WatchStopHandle;

    if (!props.staticSize) {
        unWatch = watch(
            [
                () => props.logoTypeOverride,
                () => useDisplayObj.xs.value,
                themeName
            ],
            ([logoTypeOverride, isXs, theme]) => {
                const logoType = logoTypeOverride ?? (isXs ? 'square' : 'header');

                if (!theme || !logoType) {
                    return;
                }

                setLogo(theme, logoType);
            },
            {
                immediate: true
            }
        );
    } else {
        setLogo(themeName.value, props.staticSize);
    }

    function setLogo(
        colorTheme: Nilish<'light' | 'dark'> = 'light',
        size: Nilish<keyof LogoThemeSize>
    ): void {
        if (!colorTheme || !size) {
            return;
        }
        
        // @ts-ignore
        const logoObj = logoConfig[colorTheme]?.size[size] as Nilish<LogoThemeImage>;

        if (logoObj) {
            logoUrl.value = useCdnResourceObj.getResourceCdnUrl(logoObj.url);
            imgStyle.aspectRatio = logoObj.width / logoObj.height;

            setDimensions();
            return;
        }

        logoUrl.value = '';
    }

    function setDimensions(): void {
        imgStyle.height =  props.height !== 'auto' ? `${props.height}px` : 'auto';
        imgStyle.width =  props.width !== 'auto' ? `${props.width}px` : 'auto';
    }

    const unWatchDimensions = watchEffect(() => {
        setDimensions();
    });

    // Before Destroy
    onBeforeUnmount(() => {
        unWatch?.();
        unWatchDimensions();
    });
</script>

<style lang="scss"></style>
