import type {
    ComposableOptionsBase
} from '~/types';
import { LookupKeys } from '~/constants/lookup-keys';
import { unref } from 'vue';


export interface UseCmsApiUtilsOptions extends ComposableOptionsBase {}
export function useCmsApiUtils(options: Partial<UseCmsApiUtilsOptions> = {}) {
    const $config = useRuntimeConfig();
    const {
        appCommitHash,
        brand,
        baseURL,
        browserBaseURL
    } = $config.public;
    const siteId = brand.id;
    const {
        siteIdHeader,
        siteContextHeader,
        siteVersionHeader,
        sessionIdHeader,
        siteMembershipContextHeader,
        sessionIdKey,
        orgIdSessionKey,
        orgIdHeader
    } = LookupKeys;

    const baseAjaxHeaders = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    };

    function getCommonHeaders(obj: Record<string, string> = {}): Record<string, string>{

        const siteCurrentOrgUid = '';

        return {
            // Site ID / Site Version / Site Context
            [siteIdHeader]: `${siteId}`,
            [siteVersionHeader]: `${appCommitHash}`,
            [siteContextHeader]: unref(options.siteContext) ?? '',

            // Org ID / Org Membership Context
            [orgIdHeader]: siteCurrentOrgUid,

            // Other headers
            ...obj
        };
    }
    
    return {
        getCommonHeaders,
        appCommitHash,
        brand,
        baseURL,
        browserBaseURL,
        sessionIdHeader,
        sessionIdKey,
        orgIdHeader,
        orgIdSessionKey,
        baseAjaxHeaders,
        siteMembershipContextHeader
    };
}
