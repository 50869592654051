import { isBoolish } from './is-boolish';

export const boolishToBool = (val: string | boolean): boolean | void => {
    let result;

    if (isBoolish(val)) {
        result = String(val) === 'true';
    }

    return result;
};
