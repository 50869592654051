import { boolishToBool } from '~/utils';
import { useSessionId } from '~/composables';

export default defineNuxtPlugin({
    name: 'init',
    async setup(nuxtApp) {
        const isDebug = (boolishToBool(localStorage.getItem('DEBUG') ?? false) ?? false);
        nuxtApp.vueApp.provide('isDebug', isDebug);

        const { getOrGenSessionId } = useSessionId();
        const id = await getOrGenSessionId();

        console.info('SESSION ID: ', id);
    }
});
