// REF: https://github.com/sahellebusch/flattenizer
import { isUndefined as lo_isUndefined } from 'es-toolkit';
import { isNull as lo_isNull } from 'es-toolkit';
import { isObject as lo_isObject } from 'es-toolkit/compat';
import { trimStart as lo_trimStart } from 'es-toolkit';
import { stringToPrimitive } from '~/utils';

type Nullable<A> = A | null | undefined;

type Delimiter = string;

interface IFlattened<P> {
    [path: string]: P;
}

interface IUnflattened<P> {
    [key: string]: P | P[] | IUnflattened<P>;
}

function flatten<A extends IFlattened<any>, B extends IUnflattened<any>>(
    unflattened: Nullable<B>,
    delimiter: Delimiter = '.'
): Nullable<A> {
    if (lo_isUndefined(unflattened)) {
        return undefined;
    }

    if (lo_isNull(unflattened)) {
        return null;
    }

    const flattened: A = Object.keys(unflattened).reduce(
        (acc: Record<string, string>, key: any) => {
            const value = unflattened[key];

            if (lo_isObject(value) && !lo_isNull(value)) {
                const flatObject = flatten(value, delimiter);

                for (const subKey in flatObject) {
                    // append to create new key value and assign it's value
                    acc[`${key}${delimiter}${subKey}`] = flatObject[subKey];
                }
            } else {
                acc[key] = value;
            }

            return acc;
        },
        {}
    ) as A;

    return flattened;
}

function unflatten<A extends IFlattened<any>, B extends IUnflattened<any>>(
    flattened: Nullable<A>,
    delimiter: string = '.'
): Nullable<B> {
    if (lo_isUndefined(flattened)) {
        return undefined;
    }

    if (lo_isNull(flattened)) {
        return null;
    }

    const unflattened: B = Object.keys(flattened).reduce((acc, key) => {
        explodeProperty(acc, key, flattened, delimiter);
        return acc;
    }, {} as B);

    return unflattened;
}

function flattenWithPrefix<A extends IFlattened<any>, B extends IUnflattened<any>>(
    prefix: string = '',
    unflattened: Nullable<B>,
    delimiter: Delimiter = '.'
): Nullable<A> {
    const flattened = flatten(unflattened, delimiter) as A;

    return Object.keys(flattened).reduce(
        (acc: Record<string, string>, key: any) => {
            const value = flattened[key];

            acc[`${prefix}${key}`] = value;
            return acc;
        },
        {}
    ) as A;
}

function unflattenWithPrefix<A extends IFlattened<any>, B extends IUnflattened<any>>(
    prefix: string = '',
    flattened: A,
    delimiter: string = '.'
): Nullable<B> {
    const usingPrefix = !!prefix;

    const unflattened: B = Object.keys(flattened).reduce((acc, key) => {
        // Ignore keys that don't start with the prefix
        if (usingPrefix && !key.startsWith(prefix)) {
            return acc;
        }

        const value = flattened[key];
        key = lo_trimStart(key, prefix);
        acc[key as keyof B] = value;
        return acc;
    }, {} as B);

    return unflatten(unflattened, delimiter);
}

function explodeProperty(
    currUnflattened: Record<string | number, any>,
    key: string,
    flattenedObj: Record<string, string>,
    delimiter: string
): void {
    const keys = key.split(delimiter);
    const value = stringToPrimitive(flattenedObj[key]!);
    const lastKeyIndex = keys.length - 1;

    for (let idx = 0; idx < lastKeyIndex; idx++) {
        const currKey = keys[idx];
        let nextKeyVal: any;

        if (idx === 0 && currKey === '__proto__') {
            return;
        }

        if (!currUnflattened.hasOwnProperty(currKey!)) {
            nextKeyVal = parseInt(keys[idx + 1]!, 10);
            currUnflattened[currKey!] = isNaN(nextKeyVal) ? {} : [];
        }

        currUnflattened = currUnflattened[currKey!];
    }

    currUnflattened[keys[lastKeyIndex]!] = value;
}

export function useFlattenizer(prefix: string = '', prefixDelimiter: string = ':') {
    prefix = prefix ? `${prefix}${prefixDelimiter}` : '';

    return {
        flatten: flattenWithPrefix.bind(null, prefix),
        unflatten: unflattenWithPrefix.bind(null, prefix)
    };
}
