import { useFlattenizer } from '~/composables';
import type { LocationQuery, LocationQueryRaw } from 'vue-router';

export function useRouteQueryState(prefix: string = '', prefixDelimiter: string = ':') {
    const { flatten, unflatten } = useFlattenizer(prefix);
    const route = useRoute();
    const currentState = ref<Nullable<LocationQuery>>(null);
    
    function serialize<T extends Record<string, unknown>>(valuesObj: T): Nullable<string> {
        try {
            const params = new URLSearchParams(flatten(valuesObj) ?? {});
            return params.toString();
        } catch (error) {}

        return null;
    }

    function restore<T extends object>(qs: string | LocationQuery): Nullable<T> {
        try {
            const params = new URLSearchParams(qs as any);
            const obj = Object.fromEntries(params.entries());
            const unflattenedObj = unflatten(obj);
            return unflattenedObj as T;
        } catch (error) {}
        
        return {} as T;
    }

    async function setState<T extends Record<string, any>>(obj: T): Promise<void> {
        const query = Object.assign({}, route.query, flatten(obj));
        await navigateTo({ path: route.path, query, replace: true });
        currentState.value = getState();
    }

    function getState(): Nullable<any> {
        const state = restore<LocationQuery>(route.query);
        currentState.value = state;
        return state;
    }

    async function clearState(): Promise<void> {
        const query = route.query;
        const pfx = prefix ? `${prefix}${prefixDelimiter}` : '';

        for (const key in query) {
            if (key.startsWith(pfx)) {
                delete query[key];
            }
        }

        await navigateTo({ path: route.path, query, replace: true });
        currentState.value = null;
    }

    return {
        serialize,
        restore,
        setState,
        getState,
        currentState,
        clearState
    };
}
