import type {
    captureException,
    captureMessage
} from '@sentry/browser';
import { useCustomAuth } from '~/composables';


export function useLogging() {

    function trySetSentryUser(): void {
        const { sessionObj } = useCustomAuth();
        
        if (import.meta.client && sessionObj.value && window.Sentry) {
            const {
                email,
                uid: id,
                currentMembership
            } = sessionObj.value;

            const selectedMembershipUid = currentMembership?.uid || null;

            window.Sentry.setUser({
                email,
                id,
                selectedMembershipUid
            });
        }
    }

    function logSentryError(...args: Parameters<typeof captureException>): Undefinable<string> {
        if (import.meta.client) {
            return window.Sentry?.captureException(...args);
        }
    }

    function logSentryMessage(...args: Parameters<typeof captureMessage>): Undefinable<string> {
        if (import.meta.client) {
            return window.Sentry?.captureMessage(...args);
        }
    };

    return {
        logSentryError,
        logSentryMessage,
        trySetSentryUser
    }
}
