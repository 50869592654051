import type { GenericVSelectListItem } from '~/types';

function makeInchesList(): GenericVSelectListItem[] {
    return Array(24)
        .fill(0)
        .map((o, i) => {
            const val = i * 0.5;
            return {
                text: `${val} in`,
                value: val
            };
        }) as GenericVSelectListItem[];
}

export const BodyLengthFeetList: GenericVSelectListItem[] = [
    {
        text: '',
        value: null
    },
    {
        text: '3 ft',
        value: 3
    },
    {
        text: '4 ft',
        value: 4
    },
    {
        text: '5 ft',
        value: 5
    },
    {
        text: '6 ft',
        value: 6
    },
    {
        text: '7 ft',
        value: 7
    }
    // {
    //     text: '8 ft',
    //     value: 8
    // }
];

export const BodyLengthInchesList: GenericVSelectListItem[] = [
    {
        text: '',
        value: null
    },
    ...makeInchesList()
]
