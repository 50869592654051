import type {
    Address,
    CartContactInfo,
    Employee,
    Sku,
    ShippingService,
    ShipmentEstimate
} from './';

export enum SalesTransactionType {
    ORDER = 'order',
    INVOICE = 'invoice',
    CREDIT_MEMO = 'credit_memo'
}

export enum SalesTransactionCreditMemoStatus {}

export enum SalesTransactionInvoiceStatus {
    OPEN = 'open',
    PAST_DUE = 'past_due',
    PAYMENT_PROCESSING = 'payment_processing',
    PAID = 'paid',
    VOID = 'void'
}

export enum SalesTransactionOrderStatus {
    DRAFT = 'draft',
    QUOTE = 'quote',
    NOT_SUBMITTED = 'not_submitted',
    PAYMENT_PROCESSING = 'payment_processing',
    ON_HOLD = 'on_hold',
    BACKORDER = 'backorder',
    PREORDER = 'preorder',
    PROCESSING = 'processing',
    READY_TO_SHIP = 'ready_to_ship',
    SHIPPED = 'shipped',
    DELIVERED = 'delivered',
    READY_FOR_PICKUP = 'ready_for_pickup',
    PICKED_UP = 'picked_up',
    CANCELED = 'canceled',

    /**
    *  @deprecated
    */
    PENDING = 'pending',
    /**
    *  @deprecated
    */
    IN_STOCK = 'in_stock',
    /**
    *  @deprecated
    */
    IN_ASSEMBLY = 'in_assembly',
    /**
    *  @deprecated
    */
    IN_PRODUCTION = 'in_production',
    /**
    *  @deprecated
    */
    FULFILLED = 'fulfilled',
    /**
    *  @deprecated
    */
    OUT_OF_STOCK = "out_of_stock"
}


export enum SalesTransactionOrderInternalStatus {
    DRAFT = 'draft',
    PENDING = 'pending',
    OPEN = 'open',
    RELEASED = 'released',
    POSTED = 'posted',
    COMPLETED = 'completed',
    CANCELED = 'canceled'
}

export type SalesTransactionStatus =
    typeof SalesTransactionInvoiceStatus |
    typeof SalesTransactionOrderStatus |
    typeof SalesTransactionOrderInternalStatus |
    typeof SalesTransactionCreditMemoStatus;

export enum SalesTransactionUidPrefix {
    ORDER = 'so',
    INVOICE = 'si',
    CREDIT_MEMO = 'scm'
}

export enum SalesTransactionShippingServicePreference {
    BEST_VALUE = 'best_value',
    CHEAPEST = 'cheapest',
    CHEAPEST_PLUS = 'cheapest_plus',
    FASTEST = 'fastest',
    FASTEST_PLUS = 'fastest_plus'
}

export interface SalesTransactionTypeObj {
    type: SalesTransactionType;
    title: string;
    titlePlural: string;
    itemDetailPath: string;
    permissionProp: string;
}

export interface SalesTransactionCustomerInfo {
    customerNotes: string;
}

export interface SalesTransactionGenericValueItem {
    value: string;
    url?: string;
}

export interface SalesTransactionGenericContentItem {
    label: string;
}

export interface SalesTransactionSummary extends SalesTransactionGenericContentItem, SalesTransactionGenericValueItem {}

export interface SalesTransactionTotal extends SalesTransactionGenericContentItem, SalesTransactionGenericValueItem {}

export interface SalesTransactionDetail extends SalesTransactionGenericContentItem {
    lines: SalesTransactionGenericValueItem[];
}


// Order Only
export interface SalesOrderTransactionLineExtension {
    status: Nullable<string>;
    date: Nullable<string>;
}
export interface SalesOrderTransactionExtension {
    isEditable: boolean;
}

export interface SalesOrderTransactionExpandable {
    lines?: Nullable<SalesTransactionLine[]>;
    details?: Nullable<SalesTransactionDetail[]>;
    summary?: Nullable<SalesTransactionSummary[]>;
    billToAddress?: Nullable<Address>;
    shipToAddress?: Nullable<Address>;
    pickupAddress?: Nullable<Address>;
    shippingService?: Nullable<ShippingService>;
    shippingServicePreference?: Nullable<SalesTransactionShippingServicePreference>;
    canRequestReturnLabel?: boolean;
}


export interface SalesTransactionLine extends Partial<SalesOrderTransactionLineExtension> {
    qty: number;
    unitPrice: number;
    discountedUnitPrice: Nullable<number>;
    subtotal: number;

    itemNo: string;
    referenceNo: string;
    upc: string;
    discountPct: string;

    discount: number;
    total: number;
    type: string;
    name: string;
    detail: Nilish<string>;
    imageUrl: Nilish<string>;
    salesOrderLineUid: Nilish<string>;

    // TODO: This is a guess. Need to confirm.
    bundle: Nilish<string>;

    // Expanded fields
    sku?: Nullable<Sku>;
}

export interface SalesTransactionBase {
    uid: string;
    documentNo: string;
    date: string;
    status: SalesTransactionStatus;
    internalStatus: SalesTransactionOrderInternalStatus;
    shipDate: string;
    requestedShipDate: Nullable<string>;
    requestedDeliveryDate: Nullable<string>;
    orderDate: string;
    dueDate: string;
    orderNo: string;
    purchaseOrderNo: Nilish<string>;
    paymentTerms: string;
    subtotal: number;
    discountsTotal: number;
    shippingTotal: number;
    taxTotal: number;
    total: number;
    balance: number;
    isOpen: boolean;
    isBooking: boolean;
    downloads?: Nilish<SalesTransactionGenericValueItem[]>;
    totals: SalesTransactionTotal[];
    backorderPreference?: Nilish<string>;
    source?: Nilish<string>;
    estimate?: Nilish<ShipmentEstimate>;
    shippingAccount?: Nilish<string>;
    confirmed?: Nilish<string>;
    created: string;
    updated: string;
}

export interface SalesTransaction extends
    SalesTransactionBase,
    CartContactInfo,
    SalesTransactionCustomerInfo,
    SalesOrderTransactionExpandable,
    Partial<SalesOrderTransactionExtension> {
        isDropship: boolean;
        isLocalPickup: boolean;
        isPaymentRequired: boolean;
        isTest: boolean;
    }


// Sales Person

export interface Salesperson {
    name: Nullable<string>;
    title: Nullable<string>;
    email: Nullable<string>;
    phone: Nullable<string>;
    formattedPhone: Nullable<string>;
    /**
    *  @deprecated
    */
    employee: Employee;
}


// Discounts
export interface DiscountsSummaryBase {
    uid: string;
    name: string;
    discount: string;
    discountFmt: string;
}

export interface DiscountsSummarySku extends DiscountsSummaryBase {}

export interface DiscountsSummaryProduct extends DiscountsSummaryBase {
    skus: DiscountsSummarySku[];
}

export interface DiscountsSummaryCategory extends DiscountsSummaryBase {
    products: DiscountsSummaryProduct[];
}

export interface DiscountsSummaryMarket extends DiscountsSummaryBase {
    categories: DiscountsSummaryCategory[];
}

export type DiscountsSummary = {
    markets: DiscountsSummaryMarket[];
};
