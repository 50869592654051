import type {
    QueryParamsExtended,
    CustomPaginationBase,
    ApiQueryParams,
    PaginationParams
} from '~/types';
import { clone as lo_clone } from 'es-toolkit';
import { snakeCase as lo_snakeCase } from 'es-toolkit';
import { merge as lo_merge } from 'lodash-es';
import { omitBy as lo_omitBy } from 'es-toolkit';
import { isNil as lo_isNil } from 'es-toolkit';
import { createEventHook, pausableFilter, watchWithFilter } from '@vueuse/core';


const defaultPageSize = 10;
const objPaginationRequest: PaginationParams = {
    page_size: defaultPageSize,
    page: 1
};
const objPagination: CustomPaginationBase = {
    all: false,
    count: 0,
    totalPages: 0,
    pageSize: defaultPageSize,
    page: 1,
    next: '',
    previous: '',
    hasNext: false,
    hasPrevious: false
};

// TODO: Refactor this using new Composables Options types
export function useApiQueryParams(
    key: string,
    resetOnUnload: boolean = false,
    opts: Partial<ApiQueryParams<PaginationParams>> = {},
    persistedParams: Partial<ApiQueryParams<PaginationParams>> = {}
) {
    const keyPaginationRequest = `keyPaginationRequest_${key}`;
    const keyPagination = `keyPagination_${key}`;
    const keyApiQueryParams = `keyApiQueryParams_${key}`;
    
    const queryParamsRequest = useState<QueryParamsExtended>(keyApiQueryParams, () => ({}));
    const paginationRequest = useState<PaginationParams>(keyPaginationRequest, () => lo_merge({}, objPaginationRequest, opts));
    const pagination = useState<CustomPaginationBase>(keyPagination, () => lo_clone(objPagination));

    const hasQueryParamValues = computed<boolean>(() => Object.values(queryParamsRequest.value).some((val) => !lo_isNil(val)));
    const requestParamsUpdate = createEventHook<[QueryParamsExtended, PaginationParams]>();
    const {
        eventFilter,
        pause,
        resume
    } = pausableFilter();

    // Start paused (should be an option)
    pause();

    function updatePagination(pageObj: CustomPaginationBase): void {
        pagination.value = pageObj;
        paginationRequest.value = {
            page_size: pageObj.pageSize,
            page: pageObj.page
        };
    }

    function updateOrderingParam(opts: Nilish<{key: string, order: string}>): void {
        if (!opts) {
            delete queryParamsRequest.value.ordering;
            return;
        }

        const { key, order } = opts;
        const paramName = lo_snakeCase(key || '');

        queryParamsRequest.value.ordering = `${order === 'desc' ? '-' : ''}${paramName}`;
    }

    function getApiQueryParams(): ApiQueryParams {
        const qParams = lo_omitBy(queryParamsRequest.value, lo_isNil);
        return lo_merge({}, paginationRequest.value, qParams, persistedParams);
    }

    function clearApiQueryParams(): void {
        const keys = Object.keys(queryParamsRequest.value) ?? [];
        keys.forEach((key) => queryParamsRequest.value[key] = null);
    }

    function clear() {
        queryParamsRequest.value = {};
        paginationRequest.value = lo_merge({}, objPaginationRequest, opts);
        pagination.value = lo_clone(objPagination);
    }

    function reset(): void {
        clearNuxtState([
            keyPaginationRequest,
            keyPagination,
            keyApiQueryParams
        ]);
    }

    const unWatchRequestParams = watchWithFilter(
        [queryParamsRequest, paginationRequest],
        requestParamsUpdate.trigger,
        { deep: true, eventFilter },
    );

    onBeforeUnmount(() => {
        unWatchRequestParams();

        if (resetOnUnload) {
            reset();
        }
    });

    return {
        getApiQueryParams,
        paginationRequest,
        pagination,
        updatePagination,
        updateOrderingParam,
        queryParamsRequest,
        onRequestParamsUpdate: requestParamsUpdate.on,
        requestParamsWatcherPause: pause,
        requestParamsWatcherResume: resume,
        hasQueryParamValues,
        clearApiQueryParams,
        clear,
        reset
    };
}
