import { ErrorMessages } from '~/constants';

const errorMsgProxy = new Proxy(ErrorMessages, {
	get(obj, prop: string) {
        return prop in obj ? obj[prop as keyof typeof ErrorMessages] : obj['generic'];
    }
});

export function useErrorMessage(key: keyof typeof ErrorMessages): string {
    return errorMsgProxy[key];
}
