import type {
    Address,
    NavAddress,
    SiteConfigurationObject,
    FirstLastName
} from '~/types';
import { isObject as lo_isObject } from 'es-toolkit/compat';
import { last as lo_last } from 'es-toolkit';

// REF: https://gist.github.com/jasimmk/44207f6754ebd0bc0057
export const NoPostalCodeCountries: Record<string, string> = {
    AO: 'Angola',
    AG: 'Antigua and Barbuda',
    AW: 'Aruba',
    BS: 'Bahamas',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BO: 'Bolivia, Plurinational State of',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BW: 'Botswana',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CM: 'Cameroon',
    CF: 'Central African Republic',
    TD: 'Chad',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, the Democratic Republic of the',
    CK: 'Cook Islands',
    CI: "Cote d'Ivoire",
    CW: 'Curacao',
    DJ: 'Djibouti',
    DM: 'Dominica',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    FJ: 'Fiji',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GH: 'Ghana',
    GD: 'Grenada',
    GY: 'Guyana',
    HM: 'Heard Island and McDonald Islands',
    HK: 'Hong Kong',
    // "IE: "Ireland", # Ireland came up with Postal code in 13th July 2015(Eircode) but not for whole region?
    KI: 'Kiribati',
    KP: "Korea, Democratic People's Republic of",
    LY: 'Libya',
    MO: 'Macao',
    MW: 'Malawi',
    ML: 'Mali',
    MR: 'Mauritania',
    NR: 'Nauru',
    // https://gist.github.com/jasimmk/44207f6754ebd0bc0057?permalink_comment_id=5087391#gistcomment-5087391
    // NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NU: 'Niue',
    QA: 'Qatar',
    RW: 'Rwanda',
    KN: 'Saint Kitts and Nevis',
    ST: 'Sao Tome and Principe',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SX: 'Sint Maarten (Dutch part)',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    SR: 'Suriname',
    SY: 'Syria',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago', // Planned but not implemented
    TV: 'Tuvalu',
    UG: 'Uganda',
    AE: 'United Arab Emirates',
    VU: 'Vanuatu',
    YE: 'Yemen',
    ZW: 'Zimbabwe'
};

export function useAddressesUtils() {
    
    function isAddressComplete(addressObj: Address | unknown): boolean {
        if (!lo_isObject(addressObj)) {
            return false;
        }

        const address = addressObj as Address;

        return Boolean(
            address.street1 &&
            address.city &&
            address.state &&
            address.postalCode &&
            address.country
        );
    }

    function getAddressMapUrl(companyObj: SiteConfigurationObject['brand']['company']): string | void {
        if (companyObj && companyObj.address) {
            const { address } = companyObj;
            const addrStr = [
                address.streetAddress,
                address.city,
                address.countryArea,
                address.postalCode
                //
            ]
            .join(' ')
            .replace(/\s/g, '+');

            return `https://www.google.com/maps?q=${addrStr}`;
        }
    }

    function getFirstLastName(name?: Nullable<string>): FirstLastName {
        let firstName = null;
        let lastName = null;

        if (name) {
            const nameParts = name.split(' ') || [];
            firstName = nameParts[0] ?? null;
            lastName = lo_last(nameParts) ?? null;
        }

        return { firstName, lastName };
    }

    /**
    *  @deprecated
    */
    function convertNavAddressToAddress(navAddress: NavAddress, extObj: Partial<Address> = {}): Partial<Address> {
        return {
            company: navAddress.name ?? null,
            ...getFirstLastName(navAddress?.contact),
            street1: navAddress.address,
            street2: navAddress.address2 ?? null,
            city: navAddress.city,
            state: navAddress.state,
            postalCode: navAddress.postalCode,
            country: navAddress.country,
            phone: navAddress.phone ?? null,
            email: navAddress.email ?? null,
            isShipping: false,
            isBilling: false,
            ...extObj
        };
    }

    /**
    *  @deprecated
    */
    function convertAddressToNavAddress(address: Address): NavAddress {
        return {
            name: address.company ?? '',
            contact: `${address.firstName} ${address.lastName}`,
            address: address.street1 ?? '',
            address2: address.street2 ?? '',
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
            phone: address.phone,
            email: address.email
        };
    }

    function getMissingIntlAddressData(countryCode?: Nilish<string>): Partial<Address> {
        let address: Partial<Address> = {};
        const country = countryCode?.toUpperCase();

        if (country) {
            if (NoPostalCodeCountries[country]) {
                address.postalCode = '00000';
            }

            if (country === 'HK') {
                address.city = 'Hong Kong';
                address.state = 'Hong Kong Island';
            }
        }

        return address;
    }

    return {
        isAddressComplete,
        getAddressMapUrl,
        getFirstLastName,
        convertNavAddressToAddress,
        convertAddressToNavAddress,
        getMissingIntlAddressData
    };
}
