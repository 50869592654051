import { LookupKeys, longMaxAge } from '~/constants';
import { useCustomSession, useKookies } from '~/composables';
import { nanoid } from 'nanoid';

const sessionId = ref<string>();

export function useSessionId() {
    const { getKookie, setKookie } = useKookies();
    const { sessionIdKey } = LookupKeys;
    
    async function getOrGenSessionId(fallback?: Nilish<string>): Promise<string> {
        let idFromSession: Nullable<string> = null;
        const session = await useCustomSession();

        if (session && session.data[sessionIdKey]) {
            idFromSession = await session.data[sessionIdKey];
        }

        const idFromCookie = getKookie(sessionIdKey);
        const id = idFromCookie ?? idFromSession ?? fallback ?? genSessionId();

        sessionId.value = id;

        if (import.meta.client && idFromCookie !== id) {
            setKookie(sessionIdKey, id, { maxAge: longMaxAge });
        }

        if (session) {
            await session?.update({
                [sessionIdKey]: id
            });
        }

        return id;
    }

    function genSessionId(): string {
        const id = nanoid(32);
        sessionId.value = id;
        return id;
    }

    return {
        sessionId,
        genSessionId,
        getOrGenSessionId
    };
}
