import * as v from 'valibot';
import { fieldModelErrors as fErr, txtIsRequired } from '~/constants';

export const SignInModel = v.object({
    email: v.pipe(
        v.string(fErr.email),
        v.email(`Valid email ${txtIsRequired}`)
    ),
    password: v.pipe(
        v.string(fErr.password),
        v.minLength(1, fErr.password),
        v.maxLength(256, fErr.password)
    )
});
