import type {
    UserTokenCookie
} from '~/types';
import { useKookies } from '~/composables';

export function useUserUtils() {

    const { setKookie, getKookie, getKookies, removeKookie } = useKookies();
    const userIdCookieName = 'uid';

    function setExtraCookies(cookies: Nilish<UserTokenCookie[]>, prefix?: string): void {
        if (cookies?.length) {
            cookies.forEach((cookieObj: UserTokenCookie) => {
                const { key, val } = cookieObj;
                setKookie(key, val, {}, prefix);
            });
        }
    }

    function getUserIdCookie(): Nullable<string> {
        return getKookie(userIdCookieName) ?? null;
    }

    function setUserIdCookie(uid: string) {
        const expires = new Date(Date.now() + 10e10);
        setKookie(userIdCookieName, uid, { expires });
    }

    function removeAllCookies(): void {
        const cookies = getKookies();

        if (cookies) {
            for (const key in cookies) {
                if (key.endsWith(userIdCookieName)) continue;
                removeKookie(key);
            }
        }
    }

    return {
        setExtraCookies,
        getUserIdCookie,
        setUserIdCookie,
        removeAllCookies
    };
}
