import { isNil as lo_isNil } from 'es-toolkit';
import { isError as lo_isError } from 'es-toolkit';
import { merge as lo_merge } from 'lodash-es';
import type { NuxtError } from '#app';

import type {
    PaginationParams,
    CustomPaginationBase,
    BaseCrudApiDataModel,
    UpdateApiDataParams,
    CrudDataViewOptions
} from '~/types';
import { useGlobalAlert, useDebug } from '~/composables';
import { ApiDefaults } from '~/constants/api';
import type { BaseModel } from '~/models';

const defaultOptions: CrudDataViewOptions = {
    idProp: 'uid',
    autoLoadList: true,
    messages: {
        errorDelete: ref<string>('Error Deleting. Please try again.'),
        successDelete: ref<string>('Item Deleted'),
        fatalDelete: ref<string>('Unable to delete. Please reload page.'),
        fatalEdit: ref<string>('Unable to edit. Please reload page.'),
        errorSave: ref<string>('Error saving. Please try again.'),
        successSave: ref<string>('Saved Successfully'),
        errorUpdate: ref<string>('Error saving. Please try again.'),
        successUpdate: ref<string>('Saved Successfully')
    }
}

export function useCrudDataView<T>(apiDataModel: BaseCrudApiDataModel<T>, options: DeepPartial<CrudDataViewOptions> = {}) {
    const opts = lo_merge({}, defaultOptions, options)  as CrudDataViewOptions;

    const TotalPages = () => useState<number>('keyCrudApiTotalPages', () => 1);
    const ListErrors = () => useState<NuxtError[] | Error[] | unknown[]>('keyCrudApiListErrors', () => []);

    const route = useRoute();
    const createGlobalAlert = useGlobalAlert();
    const debugConsole = useDebug();
    
    const dialogAddItem = ref(false);
    const pageNumber = ref<number>(getQsIntParam('page', ApiDefaults.startPage));
    const pageLength = ref<number>(getQsIntParam('length', ApiDefaults.pageSize));
    const totalPages = TotalPages();
    const currentEditingItem = ref<Nullable<BaseModel<T>>>();
    const currentEditingUid = ref<Nullable<string>>(null);
    const currentProgressId = ref<Nullable<string>>(null);
    const isFormProgress = ref(false);
    const listErrors = ListErrors();

    let pagination: CustomPaginationBase;

    // Sets initial call on-server load
    const {
        data: dataItems,
        status: dataItemsStatus,
        refresh: dataItemsRefresh
    } = useLazyAsyncData<Nullable<BaseModel<T>[]>>(
        getDataToken(),
        () => getItems(),
        {
            immediate: opts.autoLoadList
        }
    );

    const isLoading = computed(() => dataItemsStatus.value === 'pending');

    function getDataToken(): string {
        let key = 'keyCrudApiData';
        const parts = route.path.split('/') ?? [];
        const last = parts[parts.length - 1];

        if (last) {
            key = `${key}_${last}`;
        }

        return key;
    }

    function getQsIntParam(name: string, defaultVal: number): number {
        const q: typeof route.query = route.query;
        return !lo_isNil(q[name]) ? parseInt(`${q[name]}`, 10) : defaultVal;
    }

    function onPaginate(page: number): void {
        pageNumber.value = page;
        updateQuerystring();
        reloadItems();
    }

    async function updateQuerystring(): Promise<void> {
        await navigateTo({ query: { page: `${pageNumber.value}`, length: `${pageLength.value}` } });
    }

    function toggleItemActive(id: string, forcedState?: boolean): void {
        const item = dataItems.value?.find(
            (o: BaseModel<T>) => o?.[opts.idProp as keyof BaseModel<T>] === id
        );

        if (item) {
            const itemId = item[opts.idProp as keyof BaseModel<T>] as Nilish<string>;
            const state: boolean = !lo_isNil(forcedState) ? forcedState : !lo_isNil(currentProgressId.value);
            currentProgressId.value = state ? itemId ?? null : null;
        }
    }

    function toggleFormProgress(forcedState?: boolean): void {
        const state: boolean = !lo_isNil(forcedState) ? forcedState : !isFormProgress.value;
        isFormProgress.value = state;
    }

    function openDialog(): void {
        toggleFormProgress(false);
        dialogAddItem.value = true;
    }

    function closeDialog(): void {
        toggleFormProgress(false);
        dialogAddItem.value = false;
    }

    function onCreate(): void {
        openDialog();
    }

    function onReload(): void {
        reloadItems();
    }

    async function onDelete(uid: string): Promise<void> {
        if (uid) {
            toggleItemActive(uid, true);
            const response = await apiDataModel.deleteItem(uid);

            if (lo_isError(response)) {
                createGlobalAlert({
                    text: opts.messages.errorDelete.value,
                    show: true,
                    type: 'error'
                });
            } else {
                reloadItems();
                createGlobalAlert({
                    text: opts.messages.successDelete.value,
                    show: true,
                    type: 'success'
                });
            }

            toggleItemActive(uid, false);
        } else {
            createGlobalAlert({
                text: opts.messages.fatalDelete.value,
                show: true,
                type: 'error'
            });
            toggleItemActive(uid, false);
        }
    }

    async function onEdit(uid: string): Promise<void> {
        if (uid) {
            const item = await getItem(uid);

            if (!lo_isError(item)) {
                currentEditingUid.value = uid;
                currentEditingItem.value = item;
                dialogAddItem.value = true;
                return;
            }
        }

        createGlobalAlert({
            text: opts.messages.fatalEdit.value,
            show: true,
            type: 'error'
        });
    }

    async function onSave(data: BaseModel<T>, finalize = true): Promise<void> {
        toggleFormProgress(true);

        const response: BaseModel<T> | unknown =
            currentEditingItem.value && currentEditingUid.value
                ? await update(currentEditingUid.value, data)
                : await create(data);

        if (lo_isError(response)) {
            debugConsole.info('CRUD VIEW: SAVE: ERROR: ', response);
            createGlobalAlert({
                text: opts.messages.errorSave.value,
                show: true,
                type: 'error'
            });
            toggleFormProgress(false);
            return;
        }

        createGlobalAlert({
            text: opts.messages.successSave.value,
            show: true,
            type: 'success'
        });

        if (finalize) {
            onFinalize();
        }
    }

    async function onUpdate([uid, data, params = {}, finalize = true]: [string, BaseModel<T>, Record<string, unknown>, boolean]): Promise<void> {
        toggleFormProgress(true);

        const response: BaseModel<T> | unknown = await update(uid, data, params);

        if (lo_isError(response)) {
            createGlobalAlert({
                text: opts.messages.errorUpdate.value,
                show: true,
                type: 'error'
            });
            toggleFormProgress(false);
            return;
        }

        createGlobalAlert({
            text: opts.messages.successUpdate.value,
            show: true,
            type: 'success'
        });

        if (finalize) {
            onFinalize();
        }
    }

    async function update(uid: string, data: BaseModel<T>, params = {}): Promise<BaseModel<T> | Error> {
        const updateParams: UpdateApiDataParams<T | BaseModel<T>> = { uid, data, params } as UpdateApiDataParams<T | BaseModel<T>>;
        debugConsole.info('ON UPDATE: CURD VIEW: params: ', params);
        return await apiDataModel.updateItem(updateParams);
    }

    async function create(data: BaseModel<T>): Promise<BaseModel<T> | Error> {
        return await apiDataModel.createItem(data);
    }

    async function getItem(uid: string): Promise<BaseModel<T> | Error> {
        return await apiDataModel.getItem(uid);
    }

    async function getItems(): Promise<BaseModel<T>[]> {
        // Clear any previous errors
        listErrors.value = [];

        const params: PaginationParams = {
            page: pageNumber.value!,
            page_size: pageLength.value!
        };

        const response = await apiDataModel.getItems({ params });

        if (lo_isError(response)) {
            listErrors.value = [response];
            return [];
        }

        pagination = response.pagination;
        totalPages.value = pagination.totalPages;

        return response.data;
    }

    async function reloadItems(): Promise<void> {
        dataItemsRefresh();
    }

    function onResetParams(): void {
        window.location.href = route.path;
    }

    function resetCurrent(): void {
        currentEditingUid.value = null;
        currentEditingItem.value = null;
    }

    function onFinalize(): void {
        resetCurrent();
        closeDialog();
        reloadItems();
    }

    return {
        pageNumber,
        pageLength,
        totalPages,
        isFormProgress,
        isLoading,
        dialogAddItem,
        dataItems,
        dataItemsStatus,
        dataItemsRefresh,
        listErrors,
        currentEditingItem,
        currentEditingUid,
        currentProgressId,
        onPaginate,
        onEdit,
        onDelete,
        onSave,
        onUpdate,
        onCreate,
        onReload,
        onFinalize,
        resetCurrent,
        onResetParams,
        reloadItems,
        toggleItemActive,
        toggleFormProgress
    };
}
