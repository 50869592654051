import { createGlobalState, useStorage } from '@vueuse/core';
import type { LocalState } from '~/types';

export const useLocalState = createGlobalState(
    () => {
        const $config = useRuntimeConfig();
        const { storagePrefix } = $config.public;
        const key = `${storagePrefix}local-state`;

        return useStorage<LocalState>(key, {
            productGarmentFixedSize: null,
            productGarmentGuestBody: {}
        });
    }
);
