import type { IProductVersions } from '~/types';

export const ProductVersions: IProductVersions = {
    v0: {
        label: 'No serial code'
    },
    v1: {
        label: '5-character serial code',
        format: /^0[0-9A][0-9A-H]{3}$/,
        maxLength: 5
    },
    v2: {
        label: '9-character serial code',
        format: /^(?:[ACEFHJKLMNPRTVWXY][1-9]){3}$/,
        maxLength: 9
    },
    v3: {
        label: '6-character serial code',
        format: /^((?:[ACEFHJKLMNPRTVWXY][1-9]){2})((?:[ACEFHJKLMNPRTVWXY][1-9]){2}[ACEFHJKLMNPRTVWXY])$/,
        maxLength: 6
    }
};

export const ProductGarmentDefaultNameOnLabel = 'Custom';
