import type { CreateGlobalAlert, StateGlobalAlert } from '~/types';
import { type InjectionKey } from 'vue';

export const CreateGlobalAlertKey: InjectionKey<CreateGlobalAlert> = Symbol.for('CreateGlobalAlert');

const defaults: StateGlobalAlert = {
    text: '',
    show: false,
    showCloseButton: true,
    showReloadButton: false,
    type: 'success',
    timeout: 5000,
    link: null
};

export const stateGlobalAlert = ref<StateGlobalAlert>(Object.assign({}, defaults));

export const createGlobalAlert: CreateGlobalAlert = (options: Partial<StateGlobalAlert> = {}): StateGlobalAlert => {
    const obj = Object.assign({}, defaults, options);
    stateGlobalAlert.value = obj;
    return obj;
};

export function useGlobalAlert() {
    const createGlobalAlert = inject(CreateGlobalAlertKey);

    if (!createGlobalAlert) {
        throw new Error('Could not resolve Global Alert provider');
    }

    return createGlobalAlert;
}
