import type {
    Sku,
    ComposableOptionsBase,
    SkuFilters,
    BulkOrderInputModel
} from '~/types';
import {
    ProductAssortmentType,
    ProductAvailabilityStatus
} from '~/types';
import {
    AssortmentRatingsList,
    type BulkOrderFilterModel
} from '~/models';
import { useDebug } from '~/composables';
import { isNil as lo_isNil } from 'es-toolkit';
import type { LocationQuery } from '#vue-router';

export interface UseBulkOrderFilterOptions extends Partial<ComposableOptionsBase> {
    bulkOrderItems: BulkOrderInputModel
}
export function useBulkOrderFilter(options: UseBulkOrderFilterOptions) {

    const bulkOrderItems = readonly(options.bulkOrderItems);
    const filtersObj = reactive<SkuFilters>(new Map());
    const debugConsole = useDebug();
    const assortmentRatingsListLength = AssortmentRatingsList.length;

    function findAssortmentRatingsListIdx(assortmentRating: string): number {
        return AssortmentRatingsList.findIndex(rating => rating === assortmentRating) ?? assortmentRatingsListLength;
    };

    function createFilters(
        values: BulkOrderFilterModel,
        currentOptionsState: Nullable<LocationQuery>
    ): SkuFilters {
        // Start with a clean slate every time
        filtersObj.clear();

        const isBooking = currentOptionsState?.isBooking;
        const markets = values.marketCategory?.markets || [];
        const categories = values.marketCategory?.categories || [];

        debugConsole.info('createFilters: isBooking: ', isBooking);

        if (isBooking) {
            filtersObj.set('orderBooking', (sku: Sku, val: unknown) => {
                return !(sku.assortmentType === ProductAssortmentType.Discontinued);
            });
        } else {
            filtersObj.set('orderFillIn', (sku: Sku, val: unknown) => sku.status !== ProductAvailabilityStatus.Booking);
        }

        if (markets.length || categories.length) {
            const values = [...markets, ...categories];
            filtersObj.set('mc', (sku: Sku, val) => values.some((a) => (val as string[])?.includes(a)) ?? false);
        }

        if (!isBooking && values.status?.length) {
            filtersObj.set('status', (sku: Sku, val) => {
                if (sku.status) {
                    return values.status?.includes(sku.status) ?? false;
                }

                return false;
            });
        }

        if (!lo_isNil(values.showUpcOnly)) {
            filtersObj.set('upc', (sku: Sku, val: unknown) => values.showUpcOnly ? !!sku.upc : !sku.upc);
        }

        if (values.assortmentRating) {
            filtersObj.set('assortmentRating', (sku: Sku, val) => {
                if (values.assortmentRating && sku.assortmentRating) {
                    const idxDesired = findAssortmentRatingsListIdx(values.assortmentRating);
                    const idxCurrent = findAssortmentRatingsListIdx(sku.assortmentRating);
                    return idxCurrent <= idxDesired;
                }

                return false;
            });
        }

        if (values.showPopulatedRows) {
            const uids = getBulkOrderItemsUidList();
            filtersObj.set('uid', (sku: Sku, val: unknown) => uids.includes(sku.uid!));
        }

        if (values.assortmentType) {
            filtersObj.set('assortmentType', (sku: Sku, val: unknown) => sku.assortmentType === values.assortmentType);
        }

        if (values.showDiscountsOnly) {
            filtersObj.set('showDiscountsOnly', (sku: Sku, val: unknown) => sku.userDiscount > 0);
        }

        debugConsole.info('createFilters: filtersObj: ', filtersObj);

        return filtersObj;
    }

    function getBulkOrderItemsUidList(): string[] {
        const uids = new Set<string>();

        for (const [_, value] of bulkOrderItems) {
            if (!value.isHidden) {
                Object.keys(value.order).forEach(uid => uids.add(uid));
            }
        }

        return Array.from(uids);
    }

    return {
        filtersObj,
        createFilters
    };
}
