export function stringToPrimitive(val: string): string | number | boolean | null | undefined {
    switch(true) {
        case val === 'true' || val === 'false':
            return (val === 'true');
        case val === 'null':
            return null;
        case val === 'undefined':
            return undefined;
        case !isNaN(Number(val)):
            return Number(val);
        default:
            return val;
    }
}
