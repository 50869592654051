import {
    useDebug,
    useCustomAuth,
    useAuthUtils,
    useTimer,
    useTracking
} from '~/composables';

const legacyCookiePollingInterval = 5000;

export default defineNuxtPlugin({
    name: 'auth',
    async setup(nuxtApp) {
        const useCustomAuthObj = useCustomAuth();
        const { track } = useTracking();
        const debugConsole = useDebug();

        debugConsole.info('DEBUG AUTH: ', useCustomAuthObj);

        const {
            isLoggedIn,
            tokenAccess,
            reset,
            refreshTokens,
            isTokenExpired
        } = useCustomAuthObj;
        const { cookieNames } = useAuthUtils();

        function legacyCheckCookies() {
            if (isLoggedIn.value || tokenAccess.value) {
                const c = document.cookie;

                if (!c.includes(cookieNames.refreshExp)) {
                    reset();
                    return;
                }

                if (isTokenExpired() || !c.includes(cookieNames.access)) {
                    refreshTokens();
                }
            }
        }

        nuxtApp.hook('app:mounted', () => {
            const timer = useTimer(legacyCheckCookies, legacyCookiePollingInterval);
            legacyCheckCookies();
            timer.start();

            document.addEventListener(
                'visibilitychange',
                () => {
                    if (document.hidden) {
                        timer?.stop();
                        track('visibilitychange_hide', {});
                    } else {
                        legacyCheckCookies();
                        timer?.start();
                        track('visibilitychange_show', {});
                    }
                }
            );

            window.addEventListener(
                'beforeunload',
                () => {
                    track('beforeunload', {});
                }
            );
        });
    }
});
