// Boolish is true or false as a boolean or string: "true" or "false"
export const isBoolish = (val: string | boolean): boolean => {
    switch (String(val).toLowerCase()) {
        case 'true':
        case 'false':
            return true;
    }

    return false;
};
