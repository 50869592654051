export const ErrorMessages = {
    'globalAlert.signUp.invalidCredentials': 'The email or password you entered is incorrect. If you’re having trouble signing in, you can <a class="text-surface" href="/forgot-password">reset your password</a>.',
    'globalAlert.resetPassword.cannotReset': 'We are unable to reset your password at this time. Please try again later.',
    'globalAlert.contactForm.cannotSubmit': 'Error submitting message. Please try again.',

    // Registration
    'registration.generic': 'Could not process registration. Please try again or contact support.',

    // Reviews
    'reviews.generic': 'We are unable to process your review. Please double check the link in your email invite and try again.',
    
    // NOTE: Used as a fallback, DO NOT REMOVE
    'generic': 'Something went wrong. Please try again later.'
} as const;
