export const LookupKeys = {
    sessionIdKey: 'anid',
    sessionIdHeader: 'X-SK-Session-ID',
    siteIdHeader: 'X-SK-Site-ID',
    siteContextHeader: 'X-SK-Site-Context',
    siteVersionHeader: 'X-SK-Site-Version',
    siteMembershipContextHeader: 'X-SK-Shop-Membership-Context',
    SiteMembershipCtxPrefKey: 'org_ctx_pref',
    orgIdHeader: 'X-SK-Shop-Membership-ID',
    orgIdSessionKey: 'shop-membership-id',
    colorModeKey: 'color_mode',
    globalStripeObjName: '__SK_STRIPE_OBJ__'
} as const;
