
export const txtIsRequired = 'is required';

export const fieldModelErrors = {
    name: `Name ${txtIsRequired}`,
    firstName: `First name ${txtIsRequired}`,
    lastName: `Last name ${txtIsRequired}`,
    company: `Company ${txtIsRequired}`,
    email: `Email ${txtIsRequired}`,
    phone: `Phone ${txtIsRequired}`,
    role: `Role ${txtIsRequired}`,
    teamName: `Team name ${txtIsRequired}`,
    sport: `Sport ${txtIsRequired}`,
    level: `Level ${txtIsRequired}`,
    teamGender: `Team gender ${txtIsRequired}`,
    city: `City ${txtIsRequired}`,
    state: `State ${txtIsRequired}`,
    street1: `Address line 1 ${txtIsRequired}`,
    country: `Country ${txtIsRequired}`,
    postalCode: `Zip / Postal Code ${txtIsRequired}`,
    website: `Website ${txtIsRequired}`,
    message: `Message ${txtIsRequired}`,
    contacts: `Main contact ${txtIsRequired}`,
    height: `Height ${txtIsRequired}`,
    weight: `Weight ${txtIsRequired}`,
    gender: `Gender ${txtIsRequired}`,
    age: `Age ${txtIsRequired}`,
    password: `Password ${txtIsRequired}`,
    code: `Code ${txtIsRequired}`,
    senderName: `Sender name ${txtIsRequired}`,
    recipientName: `Recipient name ${txtIsRequired}`,
    recipientEmail: `Recipient Email ${txtIsRequired}`,
    rating: `Rating ${txtIsRequired}`,
    paymentMethod: `Payment method ${txtIsRequired}`
};
