import type {
    ComposableOptionsBase,
    MenuRequest,
    MenuItem,
    GetMenusResponse,
    DrawerMenuNames,
    DrawerMenu
} from '~/types';
import { useCustomFetch } from '~/composables';
import { isUndefined as lo_isUndefined } from 'es-toolkit';
import type { NitroFetchOptions } from 'nitropack';


export const drawerMenus = reactive<DrawerMenu>({
    main: false,
    user: false,
    cart: false,
    search: false
});
export const drawerMenusPrimary: DrawerMenuNames[] = ['main', 'user', 'cart', 'search'];
export const isOpenDrawerMenu = computed<boolean>(() => Object.values(drawerMenus).some((s) => s));
export const openedDrawerMenu = computed<Nilish<DrawerMenuNames>>(() => drawerMenusPrimary.filter((name) => !!drawerMenus[name] && name)[0]);

export function toggleDrawerMenu(
    menuName?: Nilish<keyof typeof drawerMenus>,
    value?: boolean
): void {
    const v: boolean = lo_isUndefined(value) ?
        menuName ? !drawerMenus[menuName] : false
        : value;

    drawerMenusPrimary.forEach((name) => {
        drawerMenus[name] = menuName === name ? v : false;
    });
}

export interface UseMenusOptions extends ComposableOptionsBase {}
export function useMenus(options: Partial<UseMenusOptions> = {}) {
    const apiBasePath = `/v1/menus`;
    const $_fetch = useCustomFetch({ siteContext: options.siteContext });

    async function getMenus(): Promise<GetMenusResponse | Error> {
        const url = `${apiBasePath}/`;
        let response: MenuRequest | void;

        const config: NitroFetchOptions<string> = {
            method: 'GET'
        };

        try {
            response = await $_fetch<MenuRequest>(url, config);

            return {
                menuMain: response?.header
                // menuMobile: response?.sidebar,
                // menuFooter: response?.footer
            };
        } catch (err) {
            const errorMsg = (err as Error)?.message ?? 'unknown error';
            return Error(errorMsg);
        }
    }

    return {
        getMenus,
        drawerMenus
    };
}
