import type {
    ComposableOptionsBase
} from '~/types';
import { useSession } from 'h3';

const sessionPassword = 'a8f09aba-14c9-4894-bba4-f0887d7fa03a';

export interface UseCustomSessionOptions extends ComposableOptionsBase {}
export async function useCustomSession(options: Partial<UseCustomSessionOptions> = {}): Promise<Nullable<ReturnType<typeof useSession>>> {
    const nuxtApp = useNuxtApp();

    if (import.meta.server && nuxtApp.ssrContext?.event) {
        return await useSession(nuxtApp.ssrContext.event, {
            password: sessionPassword,
            cookie: false
        });
    }

    return null;
}
