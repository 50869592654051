import type { RouteLocationRaw } from 'vue-router';
import { parseURL, parseQuery } from 'ufo';

export function pathToRouteObj(path: string): Nullable<Partial<RouteLocationRaw>> {
    let routeObj: Nullable<Partial<RouteLocationRaw>> = null;

    try {
        const obj = parseURL(path);

        if (obj) {
            routeObj = {
                query: parseQuery(obj.search),
                hash: obj.hash,
                path: obj.pathname
            } as RouteLocationRaw;
        }
    } catch (err) {}

    return routeObj;
}
