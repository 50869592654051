<template>
    <v-app>
        <v-main
            class="d-flex flex-column justify-center"
        >
            <ErrorMessage
                :status-code="statusCode"
                :error-message="errMsg"
                show-banner
            ></ErrorMessage>
        </v-main>
    </v-app>
</template>

<script lang="ts" setup>
    import type { NuxtError } from '#app';
    import {
        useDebugExec,
        useCustomError,
        useLogging
    } from '~/composables';
    import ErrorMessage from '~/components/error/ErrorMessage/ErrorMessage.vue';

    definePageMeta({
        name: 'Error',
        scrollToTop: true
    });

    
    const props = defineProps({
        error: {
            type: Object as PropType<Nilable<NuxtError | Error>>,
            default: () => ({})
        }
    });

    const $config = useRuntimeConfig();
    const brandTitle = $config.public.brand.title;

    const useCustomErrorObj = useCustomError();
    const { logSentryError } = useLogging();

    const statusCode = computed<number>(() => (props.error && 'statusCode' in props.error) ? props.error.statusCode : 500);
    const errMsg = useCustomErrorObj.getErrorMsgFromCode(statusCode);

    useHead({ title: `${errMsg} | ${brandTitle}` });

    onMounted(() => {
        logSentryError(
            props.error,
            {
                tags: {
                    section: 'error'
                }
            }
        );

        useDebugExec(() => {
            console.info('NUXT ERR OBJ: ', useError?.());
        });
    });
</script>

<style lang="scss"></style>
