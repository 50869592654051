import { useDisplay } from 'vuetify';

export function useIsMobile() {
    const useDisplayObj = useDisplay();
    const isTouch = ref<boolean>(false);
    const isBelowMinWidth = computed<boolean>(() => useDisplayObj.width.value < 360);

    if (import.meta.client) {
        isTouch.value = window?.matchMedia('(pointer: coarse)').matches ?? false;
    }

    return {
        isTouch,
        isBelowMinWidth,
        isDisplaySmAndDown: useDisplayObj.smAndDown,
        isDisplaySm: useDisplayObj.sm,
        isDisplayXs: useDisplayObj.xs,
        isMobile: useDisplayObj.mobile
    };
}
