import type {
    UserProfile,
    Salesperson,
    ShippingAccount,
    DiscountsSummary,
    Address,
    Brand,
    PaymentTerms,
    OrgEntity
} from './';


export enum SiteMembershipContext {
    Org = 'org',
    Personal = 'personal'
}

export type MembershipResourceCrudPermission = 'add' | 'view' | 'change' | 'delete';

export interface MembershipResourcePermission {
    permissions: MembershipResourceCrudPermission[];
    actions: string[];
}

export interface MembershipPermissions {
    resources: Nullable<Record<string, MembershipResourcePermission>>;
    pages: Nullable<string[]>;
    flags: Nullable<string[]>;
}

export type MembershipPermissionsType = 'resource' | 'page' | 'flag';

/**
*  @deprecated
*/
export interface MembershipEntity {
    uid: string;
    name: string;
    fullName: string;
    type: number;
    tier: number;
    hideRetailLinks: boolean;
    salesperson: Salesperson;

    // Expanders
    addresses?: Address,
    brand?: Brand;
    defaultShippingAddress?: Address;
    shippingAccounts?: ShippingAccount[];
    defaultShippingAccount?: ShippingAccount;
}

/**
*  @deprecated
*/
export interface MembershipOrg extends MembershipEntity { }
/**
*  @deprecated
*/
export interface MembershipOrgTeam extends MembershipEntity { }


/**
*  @deprecated
*/
export interface MembershipCustomerInfo {
    isOnCreditHold: boolean;
    isRetail: boolean;
}


export interface MembershipRole {
    uid: string;
    name: string;
    isOrg: boolean;
    isTeam: boolean;
}

export interface Membership {
    uid: string;
    name?: string;
    email?: string;
    role?: MembershipRole;
    entity?: OrgEntity;
    permissions?: MembershipPermissions;
    paymentTerms?: string | PaymentTerms;
    shippingAccounts?: ShippingAccount[];
    defaultShippingAccount?: ShippingAccount;
}




/**
*  @deprecated
*/
export interface MembershipMember {
    uid: string;
    isContact: boolean;
    isActive: boolean;
    created: boolean;

    // TODO: Fix type
    /**
    *  @deprecated
    */
    role: any;

    // Expanders
    user?: Partial<UserProfile>;
    org?: Nullable<MembershipOrg>;
    team?: Nullable<MembershipOrgTeam>;
}




export interface MembershipInvitation {
    uid: string;
    email: string;
    // TODO: ENUM
    status: string;
    accepted: Nullable<string>;
    declined: Nullable<string>;
    hasUser: boolean;
    pricing: DiscountsSummary;
    entity: OrgEntity;
    role: MembershipRole;
    title: string;
    message: string;
    permissions: MembershipInviteCheckListItems;
    benefits: MembershipInviteCheckListItems;
}




export interface MembershipInviteRequest {
    rawEmails: string;
}

export interface MembershipInviteCheckListItems {
    text: string;
    items: string[];
}

export interface MembershipInviteCheckFail {
    failType: 'not-found';
}

export type MembershipInviteFlowSteps = 'loading' | 'accept' | 'accepted' | 'declined' | 'alreadyAccepted' | 'alreadyDeclined' | 'userMismatch' | 'notFound' | 'serverError';






/**
*  @deprecated
*/
export interface MembershipPending {
    email: string;
    declined: Nullable<boolean>;
}


/**
*  @deprecated Use MembershipInvitation
*/
export interface MembershipInviteCheck {
    uid: string;
    email: string;
    // TODO: ENUM
    status: string;
    accepted: Nullable<string>;
    declined: Nullable<string>;
    entityName: string;
    entityFullName: string;
    entityLogoUrl: string;
    roleName: string;
    hasUser: boolean;
    pricing: DiscountsSummary;
    permissions: MembershipInviteCheckListItems;
    benefits: MembershipInviteCheckListItems;
    title: string;
    message: string;

    /**
    *  @deprecated
    */
    expires: string;
}

