import { toPlainObject as lo_toPlainObject } from 'lodash-es';
import { merge as lo_merge } from 'lodash-es';

export class BaseModel<T> {
    id: number | undefined = undefined;
    uid: string | undefined = undefined;

    static new(): InstanceType<typeof this> {
        return new this();
    }

    static getBaseProps() {
        return lo_toPlainObject(new BaseModel());
    }

    static toPlainObject(obj?: any) {
        const inst = new this();
        lo_merge(inst, obj);
        return lo_toPlainObject(inst);
    }

    static toPlainFilteredObject<TT = any>(obj: any): TT {
        return lo_toPlainObject(obj);
    }

    toPlainObject(): T {
        return lo_toPlainObject(this);
    }

    toPlainFilteredObject(): T {
        return lo_toPlainObject(this);
    }

    removeExtraProps(): Partial<this> {
        return this;
    }
}
