import { EthnicityInputValueKeys } from '~/types';
import type { EthnicityListItem } from '~/types';

export const EthnicityList: EthnicityListItem[] = [
    {
        ethnicity: EthnicityInputValueKeys.None,
        text: 'No Answer'
    },
    {
        ethnicity: EthnicityInputValueKeys.Asian,
        text: 'Asian'
    },
    {
        ethnicity: EthnicityInputValueKeys.Black,
        text: 'Black'
    },
    {
        ethnicity: EthnicityInputValueKeys.Hispanic,
        text: 'Hispanic'
    },
    {
        ethnicity: EthnicityInputValueKeys.White,
        text: 'White'
    },
    {
        ethnicity: EthnicityInputValueKeys['Native American'],
        text: 'Native American'
    },
    {
        ethnicity: EthnicityInputValueKeys.Unknown,
        text: 'Not Listed'
    }
];
