import { isString as lo_isString } from 'es-toolkit';

export function parseUrlStr(urlStr: string | unknown): Nilish<URL> {
    if (!lo_isString(urlStr) || !urlStr) return;

    try {
        return new URL(urlStr);
    } catch (err) {}
}

