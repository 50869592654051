import type { MaybeRef } from '@vueuse/core';
import type { RouteLocationNormalized } from 'vue-router';
import type {
    ComposableOptionsBase,
    Sku,
    Cart,
    CartLine
} from '~/types';
import type { NitroFetchOptions } from 'nitropack';
import type { LocationQuery, LocationQueryValue } from 'vue-router';
import { getSingleParamValue, noop } from '~/utils';
import {
    useUserUtils,
    useBrowserInfo,
    useTrackingUtils
    // useCustomAuth
} from '~/composables';
import { createSharedComposable } from '@vueuse/core';
import { unref } from 'vue';


export interface UseTrackingOptions extends ComposableOptionsBase {
    route?: RouteLocationNormalized
}
export function useTrackingStandalone(options: Partial<UseTrackingOptions> = {}) {
    if (import.meta.server) {
        console.warn('\n\nDO NOT USE TRACKING STANDALONE IN SERVER MODE\n\n');
    }

    const $config = useRuntimeConfig();
    const brandTitle = $config.public.brand.title as string;
    const {
        appName,
        appId,
        appVersion,
        appCommitHash,
        stagingEnv,
        isGTMEnabled,
        isGA4Enabled,
        isMetaFbPixelEnabled
    } = $config.public;

    const currency = 'USD';
    const apiBasePath = `/v1/event`;
    const $_fetch = useCustomFetch();
    const route = options.route ?? useRoute();
    const useUserUtilsObj = useUserUtils();
    const {
        setTrackerId,
        getTrackers,
        removeTrackers
    } = useTrackingUtils();
    const {
        staticIsMobile,
        staticDeviceType,
        staticDeviceTypeSpecific
    } = useBrowserInfo();
    // const {
    //     sessionObj
    // } = useCustomAuth();


    const deviceTypeObj = {
        deviceTypeSpecific: staticDeviceTypeSpecific,
        deviceType: staticDeviceType,
        isMobile: staticIsMobile.value
    };
    const connectionTypeObj = {
        // @ts-ignore
        downlinkInMbps: navigator?.connection?.downlink ?? -1,
        // @ts-ignore
        connEffectiveType: navigator?.connection?.effectiveType ?? ''
    };
    const userInfoObj = {
        language: navigator?.language ?? '',
        timezone: Intl?.DateTimeFormat?.().resolvedOptions?.().timeZone ?? ''
    };
    const appInfoObj = {
        appName,
        appId,
        appVersion,
        appCommitHash,
        stagingEnv
    };

    function getMetaExtras(): Record<string, unknown> {
        return {
            ...deviceTypeObj,
            ...connectionTypeObj,
            ...userInfoObj,
            ...appInfoObj,
            prevUrl: window.history?.state?.back ?? null,
            /**
            *  @deprecated
            */
            tracker: getTrackers(),
            queryParams: {
                ...route.query
            }
        };
    }

    function track(type: string, data: MaybeRef<any>, ...args: any): void {
        data = unref(data);

        switch (type) {
            case 'login':
            case 'sign_in':
                send(type, {
                    ga4_method: data.method
                });
                break;
            case 'sku':
                trackSku(data);
                break;
            case 'begin_checkout':
            case 'view_cart':
            case 'remove_from_cart':
            case 'purchase':
                trackCart(type, data, ...args);
                break;
            case 'add_to_cart':
                const obj = { ecommerce: data };
                send(type, obj, obj);
                break;
            case 'view_page':
                sendSk(type, data);
                break;
            default:
                send(type, data, ...args);
        }
    }

    function trackSku(sku: MaybeRef<Sku>) {
        sku = unref(sku);

        const basePayload = {
            currency,
            value: sku.price,
            /**
            *  @deprecated
            */
            availability_status: sku.status,
            status: sku.status,
            items: [
                {
                    item_id: sku.uid,
                    item_name: sku.name,
                    item_brand: brandTitle,
                    item_category: sku.marketName,
                    item_category2: sku.categoryName,
                    item_discount: sku.price - sku.userPrice,
                    price: sku.price,
                    quantity: 1,
                    /**
                    *  @deprecated
                    */
                    availability_status: sku.status,
                    status: sku.status
                }
            ]
        };

        const payload = {
            ecommerce: {
                ...basePayload,
                ...getMetaExtras()
            }
        };

        send('view_item', { ecommerce: payload }, { ecommerce: basePayload });
    }

    function trackCart(type: string, cart: MaybeRef<Cart>, itemsOverride?: CartLine[]) {
        cart = unref(cart);

        if (!cart?.lines) {
            return;
        }

        const isPurchase = type === 'purchase';

        const items = (itemsOverride ?? cart.lines)?.map((item: CartLine) => ({
            item_id: item.uid,
            item_name: item.sku?.name,
            item_brand: brandTitle,
            item_category: item.sku?.marketName,
            item_category2: item.sku?.categoryName,
            item_discount: item.adjustmentsTotal,
            sku_uid: item.sku?.uid,
            price: item.unitPrice,
            quantity: item.qty
        }));

        const basePayload: Record<string, any>  = {
            currency,
            items,
            value: cart.total,
            transaction_id: cart.uid
        };

        if (isPurchase) {
            basePayload.shipping = cart.shippingTotal;
            basePayload.tax = cart.taxTotal;
        }

        const payload = {
            ...basePayload,
            ...getMetaExtras()
        };

        send(type, { ecommerce: payload }, { ecommerce: basePayload });

        if (isPurchase) {
            const { value } = basePayload;
            trackFb('Purchase', { currency, value });
        }
    }

    // Internal
    function send(event: string, obj: object = {}, objSk: object = {}) {
        if (isGTMEnabled) {
            window.dataLayer?.push?.({
                event,
                ...obj
            });
        } else if (isGA4Enabled) {
            const payload = 'ecommerce' in obj ? obj.ecommerce : obj;
            window.gtag?.('event', event, payload);
        }

        sendSk(event, objSk);
    }

    function trackFb(event: string, obj: object = {}): void {
        if (isMetaFbPixelEnabled) {
            window.fbq?.('track', event, obj);
        }
    }

    async function sendSk(eventType: string, meta: object = {}): Promise<void> {
        const uid = useUserUtilsObj.getUserIdCookie();
        const urlApi = `${apiBasePath}/`;
        const url = window.location.href;
        const urlReferrer = document.referrer;

        meta = Object.assign({}, meta, {
            uid,
            urlReferrer,
            ...getMetaExtras()
        });


        /**
        *  @deprecated Pull directly from getTrackers
        */
        const trackers = 'tracker' in meta ? meta.tracker : null;

        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: {
                eventType,
                meta,
                trackers,
                url
            }
        };

        try {
            await $_fetch(urlApi, config);
        } catch (err) {
            // @ts-ignore
            const badTrackers: Nilish<string[]> = err?.data?.trackers;
            if (Array.isArray(badTrackers)) {
                removeTrackers(badTrackers);
            }
        }
    }

    function processTrackingParams(params: LocationQuery): void {
        for (const [key, value] of Object.entries(params)) {
            processTrackingParam(key, value);
        }
    }

    function processTrackingParam(key: string, value: LocationQueryValue | LocationQueryValue[]): void {
        switch (key) {
            case 'uid':
                value = getSingleParamValue(value);
                if (value.startsWith('usr_')) {
                    useUserUtilsObj.setUserIdCookie(value);
                }
                break;
            case 'source':
            case 'batch':
            case 'cohort':
                value = getSingleParamValue(value);
                useUserUtilsObj.setExtraCookies([{ key, val: value }]);
                break;
            case 'tracker':
                setTrackerId(value as string | string[]);
                break;
        }
    }

    // callOnce('keyUseTracking', () => {
    //     window?.fbq('init', '');
    //     window.fbq('track', 'PageView');
    // });

    return {
        track,
        processTrackingParams
    };
}

export const useTracking = createSharedComposable(
    (import.meta.server) ? () => ({
        track: noop,
        processTrackingParams: noop
    }) : useTrackingStandalone
);
