import type {
    CustomPagination,
    PaginatedRequest,
    PaginatedPayload,
    UpdateApiDataParams,
    GenericCrudMethodsOptions,
    BaseCrudApiDataModel
} from '~/types';
import type { NitroFetchOptions } from 'nitropack';
import type { BaseModel } from '~/models';
import { useApiUtils, useCustomFetch, useCustomAuth } from '~/composables';
import { omit as lo_omit } from 'es-toolkit';
import { merge as lo_merge } from 'lodash-es';
import { createEventHook } from '@vueuse/core';

export function useGenericCrudMethods<T>(apiBasePath: string, ModelObj: typeof BaseModel<T>, options: GenericCrudMethodsOptions = {}): BaseCrudApiDataModel<T> {
    // type ModelType = InstanceType<typeof ModelObj>;
    type ModelType = BaseModel<T>;
    
    const useApiUtilsObj = useApiUtils();
    const { getSession } = useCustomAuth();
    const $_fetch = useCustomFetch();
    
    const getItemsResult = createEventHook<PaginatedPayload<ModelType>>();
    const getItemResult = createEventHook<ModelType>();
    const createItemResult = createEventHook<ModelType>();
    const updateItemResult = createEventHook<ModelType>();
    const deleteItemResult = createEventHook<unknown>();
    const errorResult = createEventHook<Record<string, any>>();


    async function getItems(args: PaginatedRequest): Promise<PaginatedPayload<ModelType> | Error> {
        const extraParams = options?.itemsQueryParams ?? {};
        const url = args?.url ?? `${apiBasePath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'GET'
        };

        config.query = lo_merge(extraParams, args?.params);

        let bodies: ModelType[];
        let paginatedPayload: PaginatedPayload<ModelType>;

        try {
            const response = await $_fetch<CustomPagination<T>>(url, config);

            // Adds special properties
            bodies = response.results.map<ModelType>((obj) => ModelObj.toPlainObject(obj));

            paginatedPayload = {
                pagination: lo_omit(response, ['results']),
                data: bodies
            };

            getItemsResult.trigger(paginatedPayload);
            return paginatedPayload;
        } catch (err) {
            emitError('getItems', err);
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getItem(uid: string): Promise<ModelType | Error> {
        const url = `${apiBasePath}/${uid}/`;

        try {
            const response = await $_fetch<T>(url);
            const output = ModelObj.toPlainObject(response) as ModelType;
            getItemResult.trigger(output);
            return output;
        } catch (err) {
            emitError('getItem', err);
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function createItem(data: Partial<T | ModelType>): Promise<ModelType | Error> {
        const url = `${apiBasePath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: data
        };

        try {
            const response = await $_fetch<T>(url, config);
            await getSession(true);
            const output = ModelObj.toPlainObject(response) as ModelType;
            createItemResult.trigger(output);
            return output;
        } catch (err) {
            emitError('createItem', err);
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateItem(args: UpdateApiDataParams<T | ModelType>): Promise<ModelType | Error> {
        const url = `${apiBasePath}/${args.uid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: args.data
        };

        try {
            const response = await $_fetch<T>(url, config);
            await getSession(true);
            updateItemResult.trigger(response as ModelType);
            return ModelObj.toPlainObject(response);
        } catch (err) {
            emitError('updateItem', err);
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function deleteItem(uid: string): Promise<unknown> {
        const url = `${apiBasePath}/${uid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE'
        };

        try {
            const response = await $_fetch.raw<unknown>(url, config);

            if (!response || response?.status > 399) {
                throw new Error('Error deleting item.');
            }

            await getSession(true);
            deleteItemResult.trigger(response);
            return response;
        } catch (err) {
            emitError('deleteItem', err);
            return err;
        }
    }

    function emitError(ctx: string, err: unknown): void {
        const errBody = useApiUtilsObj.getDataFromError(err);

        if (errBody) {
            errBody.__context = ctx;
            errorResult.trigger(errBody);
        }
    }

    return {
        getItems,
        getItem,
        createItem,
        updateItem,
        deleteItem,
        onGetItems: getItemsResult.on,
        onGetItem: getItemResult.on,
        onCreateItem: createItemResult.on,
        onUpdateItem: updateItemResult.on,
        onDeleteItem: deleteItemResult.on,
        onErrorResult: errorResult.on
    };
}
